<template>
	<v-menu
		:close-on-content-click="false"
		v-model="datepicker"
		:nudge-right="40"
		transition="scale-transition"
		offset-y
		min-width="290px">
		<template v-slot:activator="{on}">
			<v-text-field
				@click:clear="clearClicked()"
				v-on="on"
				:value="formattedDate"
				:label="label"
				clearable
				readonly/>
		</template>

		<v-date-picker
			v-model="pickerValue" no-title scrollable
			@input="valueChanged()"/>
	</v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Formatter } from '@/date/date-format'
import { Moment } from 'moment'

@Component
export default class DatepickerWidget extends Vue {
	@Prop({ default: '' })
	label: string

	@Prop({ default: '' })
	value: Moment | string

	internalValue: Moment | undefined | null = null
	pickerValue: string = ''

	datepicker = false

	get formattedDate() {
		return Formatter.format(this.pickerValue)
	}

	created() {
		if (!this.internalValue) {
			// empty initial value, set it to be same as the shown value in text
			this.internalValue = Formatter.pickerMoment(this.pickerValue)
		}
	}

	@Watch('value', { immediate: true })
	watchValue() {
		if (this.value === this.internalValue) {
			return
		}

		this.internalValue = Formatter.pickerMoment(this.value)
		this.$emit('input', this.internalValue)
	}

	@Watch('internalValue', { immediate: true })
	watchInternalValue() {
		this.pickerValue = Formatter.pickerFormat(this.internalValue)
		this.$emit('input', this.internalValue)
	}

	valueChanged() {
		this.datepicker = false
		this.internalValue = Formatter.pickerMoment(this.pickerValue)
	}

	clearClicked() {
		this.internalValue = null
	}
}
</script>

<style scoped lang="scss">
</style>
