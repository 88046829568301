import { Job } from '@/models/job'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { CurrentStatus } from '@/models/currentstatus'
import { JobProgress } from '@/models/jobProgress'
import { DropdownDatasource } from '@/components/widgets/models/models'

class JobService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list('', '', '', config)
		}
	}

	listJobsPastWeek(config?: RequestConfig): HttpResponse<Job[]> {
		return http.get('/secured/job/list/pastweek', config)
	}

	searchJobs(filter: string = '', config?: RequestConfig): HttpResponse<Job[]> {
		return http.get(`/secured/job/list?filter=${filter}`, config)
	}

	searchJobsBy(searchBy: string, searchByKeyword: string, fromDate: string, toDate: string, status: string, isStatus: string, filter: string = '', config?: RequestConfig): HttpResponse<Job[]> {
		return http.get(`/secured/job/search?searchby=${searchBy}&searchbykeyword=${searchByKeyword}
		&fromdate=${fromDate}&todate=${toDate}&status=${status}&isstatus=${isStatus}&filter=${filter}`, config)
	}

	searchJobsByForPlan(searchBy: string, searchByKeyword: string, fromDate: string, toDate: string, status: string, isStatus: string, filter: string = '', config?: RequestConfig): HttpResponse<Job[]> {
		return http.get(`/secured/job/searchForPlan?searchby=${searchBy}&searchbykeyword=${searchByKeyword}
		&fromdate=${fromDate}&todate=${toDate}&status=${status}&isstatus=${isStatus}&filter=${filter}`, config)
	}

	list(status: string, fromDate: string, toDate: string, config?: RequestConfig): HttpResponse<Job[]> {
		const url = `/secured/job/list?status=${status}&fromdate=${fromDate}&todate=${toDate}`

		return http.get(url, config)
	}

	jobProgressListByID(taskId: number, config?: RequestConfig): HttpResponse<JobProgress[]> {
		const url = `/secured/jobprogress/list/${taskId}`

		return http.get(url, config)
	}

	create(data: Partial<Job>, config?: RequestConfig): HttpResponse<Job> {
		return http.post('/secured/job', data, config)
	}

	jobCurrentStatus(aggregateId: string, config?: RequestConfig): HttpResponse<CurrentStatus> {
		return http.get(`/secured/job/${aggregateId}/current`, config)
	}

	startJobFlag(
		jobID: string,
		config?: RequestConfig,
	): HttpResponse {
		return http.put(`/secured/job/${jobID}/ready`, config)
	}

	updateJobDetail(jobID: string, data: Partial<Job>, config?: RequestConfig): HttpResponse<Job> {
		return http.put(`/secured/job/${jobID}`, data, config)
	}

	deleteJob(
		jobID: string,
		config?: RequestConfig,
	): HttpResponse {
		return http.delete(`/secured/job/${jobID}`, config)
	}

	getJob(aggregateID: string, config?: RequestConfig): HttpResponse<Job> {
		return http.get(`/secured/job/${aggregateID}`, config)
	}
}

export default new JobService()
