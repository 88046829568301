<template>
	<div class="form-label-container">
		<label class="v-label v-label--active theme--light">{{label}}</label>
		<div class="form-label-value">
			{{value}}
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormLabel extends Vue {
	@Prop({ default: '' }) label: string

	@Prop({ default: '' }) value: string
}
</script>

<style scoped lang="scss">
	.form-label-container {
		height: 52px;
		text-align: left;
	}

	label {
		color: rgba(0, 0, 0, .54);
		height: 20px;
		line-height: 20px;
		font-size: 12px;
	}

	.form-label-value {
		font-size: 16px;
	}
</style>
