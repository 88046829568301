import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from '@/plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@/assets/styles/app.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import ECharts from 'vue-echarts/components/ECharts.vue'
import 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import './filters'
import './components'
import VeeValidate from 'vee-validate'
// @ts-ignore

Vue.component('chart', ECharts)
Vue.use(VeeValidate)

new Vue({
	router,
	vuetify,
	store,
	render: h => h(App),
}).$mount('#app')
