<template>
	<v-autocomplete :label="label"
									:items="internalItems"
									:loading="loading"
									:item-text="itemText" :item-value="itemValue"
									v-model="internalValue"
									@input="valueChanged"></v-autocomplete>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DropdownDatasource } from '@/components/widgets/models/models'

@Component({})
export default class DropdownWidget extends Vue {
	@Prop({ default: '' })
	label: string

	@Prop({ default: '' })
	value: string

	@Prop()
	datasource: DropdownDatasource

	@Prop({ default: () => [] })
	items: any[]

	@Prop({ default: 'name' })
	itemText: string

	@Prop({ default: 'id' })
	itemValue: string

	internalItems: any[] = []

	loading = false

	internalValue = ''

	valueChanged() {
		this.$emit('input', this.internalValue)
	}

	@Watch('value', { immediate: true })
	watchValue() {
		if (this.value === this.internalValue) {
			return
		}

		this.internalValue = this.value
	}

	@Watch('datasource', { immediate: true })
	watchDatasource() {
		if (!this.datasource) {
			return
		}

		this.loading = true
		this.datasource({})
			.then(r => (this.internalItems = r.data))
			.catch(reason => {
				// console.log('unable to load datasource', reason)
			})
			.finally(() => (this.loading = false))
	}

	@Watch('items', { immediate: true })
	watchItems() {
		if (this.datasource || !this.items) {
			// use datasource as priority
			return
		}

		this.internalItems = this.items
	}
}
</script>

<style scoped lang="scss">
</style>
