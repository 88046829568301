import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Dashboard from './views/Dashboard.vue'
import JobListing from './views/job/JobListing.vue'
import WorkflowListing from './views/workflow/WorkflowListing.vue'
import Casing from './views/casing/CasingListing.vue'
import LossBonusListing from './views/lossbonus/LossBonusListing.vue'
import JobDetail from './views/job/JobDetail.vue'
import MoveOut from './views/MoveOut.vue'
import TaskDetail from './views/taskDetail.vue'
import MoveInQty from './views/MoveInQty.vue'
import JobProgressListing from './views/job/JobProgressListing.vue'

const Master = () => import('@/views/master/Master.vue')
const JobCRUD = () => import('@/views/job/JobCRUD.vue')
const JobCRUD2 = () => import('@/views/job/JobCRUD2.vue')
const Task = () => import('@/views/Task.vue')
const Product = () => import('@/views/product/Product.vue')
const UpdateProduct = () => import('@/views/product/UpdateProduct.vue')
const DataSources = () => import('@/views/data-sources/DataSources.vue')
const Equipment = () => import('@/views/equipment/Equipment.vue')

// admin
const Account = () => import('@/views/admin/Account.vue')
const Roles = () => import('@/views/admin/Roles.vue')
const RolePermission = () => import('@/views/admin/RolePermission.vue')
const ConfigureUI = () => import('@/views/admin/ConfigureUI.vue')
const Settings = () => import('@/views/admin/Settings.vue')

// reports
const ProductReport = () => import('@/views/reports/Product.vue')
const JobReport = () => import('@/views/reports/Job.vue')

// legacy components
const LegacyDatasource = () => import('@/views/legacy/LegacyDatasource.vue')
const LegacyProduct = () => import('@/views/legacy/LegacyProduct.vue')
const LegacyMaster = () => import('@/views/legacy/LegacyMaster.vue')
const LegacyJob = () => import('@/views/legacy/LegacyJob.vue')
const LegacyImport = () => import('@/views/legacy/LegacyImport.vue')

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/home',
			name: 'home',
			component: Home,
			children: [
				{
					path: '',
					redirect: { name: 'dashboard' },
				},
				{
					path: '/admin/role',
					name: 'role',
					component: Roles,
				},
				{
					path: '/admin/role/:id',
					name: 'configureRole',
					component: RolePermission,
				},
				{
					path: '/admin/account',
					name: 'account',
					component: Account,
				},
				{
					path: '/admin/ui',
					name: 'configUI',
					component: ConfigureUI,
				},
				{
					path: '/admin/settings',
					name: 'settings',
					component: Settings,
				},
				{
					path: '/dashboard',
					name: 'dashboard',
					component: Dashboard,
				},
				{
					path: '/product',
					name: 'product',
					component: Product,
					children: [
						{
							path: ':id',
							name: 'updateProduct',
							component: UpdateProduct,
							props: true,
						},
					],
				},
				{
					path: '/master',
					name: 'master',
					component: Master,
				},
				{
					path: '/crudJob',
					name: 'crudJob',
					component: JobCRUD,
				},
				{
					path: '/crudJob2',
					name: 'crudJob2',
					component: JobCRUD2,
				},
				{
					path: '/job',
					name: 'job',
					component: JobListing,
				},
				{
					path: '/workflow',
					name: 'workflow',
					component: WorkflowListing,
				},
				{
					path: '/casing',
					name: 'casing',
					component: Casing,
				},
				{
					path: '/lossbonus',
					name: 'lossbonus',
					component: LossBonusListing,
				},
				{
					path: '/moveout',
					name: 'moveout',
					component: MoveOut,
				},
				{
					path: '/moveinqty',
					name: 'moveinqty',
					component: MoveInQty,
				},
				{
					path: '/jobDetail/:id',
					name: 'jobDetail',
					component: JobDetail,
				},
				{
					path: '/task',
					name: 'task',
					component: Task,
				},
				{
					path: '/task/:id',
					name: 'taskdetail',
					component: TaskDetail,
				},
				{
					path: '/datasource/:type',
					name: 'datasource',
					component: DataSources,
				},
				{
					path: '/jobprogress',
					name: 'jobprogress',
					component: JobProgressListing,
				},
				{
					path: '/productReport',
					name: 'productReport',
					component: ProductReport,
				},
				{
					path: '/jobReport',
					name: 'jobReport',
					component: JobReport,
				},
				{
					path: '/equipment',
					name: 'equipment',
					component: Equipment,
				},
				{
					path: '/legacy/datasource',
					name: 'legacyDatasource',
					component: LegacyDatasource,
				},
				{
					path: '/legacy/product',
					name: 'legacyProduct',
					component: LegacyProduct,
				},
				{
					path: '/legacy/master',
					name: 'legacyMaster',
					component: LegacyMaster,
				},
				{
					path: '/legacy/job',
					name: 'legacyJob',
					component: LegacyJob,
				},
				{
					path: '/legacy/import',
					name: 'legacyImport',
					component: LegacyImport,
				},
			],
		},
	],
})

export default router
