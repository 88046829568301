import { MoveIn, MoveOut, Task } from '@/models/task'
import { http, HttpResponse, RequestConfig } from '@/http-client'

class TaskService {
	list(aggregateId: string, config?: RequestConfig): HttpResponse<Task[]> {
		return http.get(`/secured/task/list/${aggregateId}`, config)
	}

	getTaskById(id: string, config?: RequestConfig): HttpResponse<Task[]> {
		return http.get(`/secured/task/${id}`, config)
	}

	listAll(config?: RequestConfig): HttpResponse<Task[]> {
		return http.get(`/secured/task/list`, config)
	}

	moveIn(
		aggregateId: string,
		moveInQty: MoveIn,
		config?: RequestConfig,
	): HttpResponse {
		return http.post(`/secured/job/${aggregateId}/movein`, moveInQty, config)
	}

	moveOut(
		aggregateId: string,
		moveOutQty: MoveOut,
		config?: RequestConfig,
	): HttpResponse {
		return http.post(`/secured/job/${aggregateId}/moveout`, moveOutQty, config)
	}
}

export default new TaskService()
