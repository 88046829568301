import moment, { Moment } from 'moment'

const dateFormat = 'DD MMM YYYY'
const pickerFormat = 'YYYY-MM-DD'
const hourFormat = 'HH:mm:ss'
const dateTimeFormat = 'DD MMM YYYY HH:mm:ss'

class DateFormatter {
	format(date?: Date | string | Moment): string {
		if (!date) {
			return ''
		}

		return moment(date).format(dateFormat)
	}

	formatHour(date?: Date | string): string {
		if (!date) {
			return ''
		}

		return moment(date).format(hourFormat)
	}

	formatDateTime(date?: Date | string): string {
		if (!date) {
			return ''
		}

		return moment(date).format(dateTimeFormat)
	}

	parse(date: Date | string): Date {
		if (date instanceof Date) {
			return date
		}
		return moment(date).toDate()
	}

	// formats date / date string to picker's format
	pickerFormat(date?: Date | string | Moment | null): string {
		if (!date) {
			return moment().format(pickerFormat)
		}

		return moment(date).format(pickerFormat)
	}

	// serialize picker's value to string
	pickerSerialize(date?: Date | string): string {
		if (!date) {
			return ''
		}

		return moment(date, pickerFormat).format()
	}

	pickerMoment(date?: Date | string | Moment): Moment | undefined {
		if (!date) {
			return
		}

		return moment(date)
	}
}

const Formatter = new DateFormatter()

export { Formatter }
