import Vue from 'vue'
import DatepickerWidget from '@/components/widgets/form/DatepickerWidget.vue'
import DropdownWidget from '@/components/widgets/form/DropdownWidget.vue'
import ListingWidget from '@/components/widgets/form/ListingWidget.vue'
import CRUDComponent from '@/components/CRUDComponent.vue'
import EditableLabel from '@/components/widgets/form/EditableLabel.vue'
import FormLabel from '@/components/widgets/form/FormLabel.vue'

Vue.component('date-picker', DatepickerWidget)
Vue.component('dropdown', DropdownWidget)
Vue.component('editable-label', EditableLabel)
Vue.component('form-label', FormLabel)

// CRUD components
Vue.component('listing', ListingWidget)
Vue.component('crud-component', CRUDComponent)
