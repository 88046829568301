<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NormalizedScopedSlot } from 'vue/types/vnode'
import { CompleteAccount } from '@/models/account'

@Component
export default class AccessRestricted extends Vue {
	@Prop({ required: true }) module: string
	@Prop({ default: () => [] }) actions: string[]

	hasAccess = true
	$scopedSlots: {
		default: NormalizedScopedSlot,
		noAccess: NormalizedScopedSlot,
	}

	render() {
		const permissions = (this.$store.state.profile.account as CompleteAccount).permissions
		if (!permissions) {
			return
		}
		const accessActions = permissions[this.module]

		let actionsToCheck = this.actions
		if (this.actions.length === 0) {
			// no actions, means check if it has module
			actionsToCheck = [this.module]
		}
		this.hasAccess = permissions.hasOwnProperty(this.module) && actionsToCheck.every(action => accessActions.indexOf(action) !== -1)

		if (!this.hasAccess) {
			if (!this.$scopedSlots.noAccess) {
				return
			}
			return this.$scopedSlots.noAccess({})
		}

		return this.$scopedSlots.default({})
	}
}
</script>
