import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DatasourceItem } from '@/models/datasource'
import { DropdownDatasource, DropdownParams } from '@/components/widgets/models/models'

class DatasourceService {
	dropdownDatasource(type: string): DropdownDatasource {
		return ({ config }: DropdownParams) => {
			return this.listItems(type, config)
		}
	}

	listItems(
		type: string,
		config?: RequestConfig,
	): HttpResponse<DatasourceItem[]> {
		return this.list(type, config)
	}

	list(type: string, config?: RequestConfig): HttpResponse<DatasourceItem[]> {
		return http.get<DatasourceItem[]>(`/secured/datasource/list/${type}`, config)
	}

	createItem(
		datasourceType: string,
		data: DatasourceItem,
		config?: RequestConfig,
	): HttpResponse<DatasourceItem[]> {
		return http.post(`/secured/datasource/${datasourceType}`, data, config)
	}

	updateItem(
		datasourceType: string,
		data: DatasourceItem,
		config?: RequestConfig,
	): HttpResponse<DatasourceItem[]> {
		return http.put(`/secured/datasource/${datasourceType}`, data, config)
	}

	deleteItem(
		datasourceType: string,
		itemId: number,
		config?: RequestConfig,
	): HttpResponse<DatasourceItem[]> {
		return http.delete(`/secured/datasource/${datasourceType}/${itemId}`, config)
	}
}

export default new DatasourceService()
