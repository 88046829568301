<template>
	<v-app>
		<v-app-bar app clipped-left dark>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer" />
			<v-toolbar-title>
				<img
					alt="logo"
					:src="logoUrl"
					style="display: inline-block; vertical-align: middle; max-width: 24px; max-height: 24px"
				/>
				{{ appName }}
			</v-toolbar-title>
			<div v-if="isSales" style="margin-left: 10px; margin-right:10px;">
				<a :href="salesUrl">To Sales &rarr;</a>
			</div>
			<v-spacer />
			{{ userName }}
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" clipped app>
			<v-toolbar flat class="transparent">
				<v-list class="pa-0">
					<v-list-item>
						<v-list-item-avatar>
							<v-icon>account_circle</v-icon>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title>{{ userName }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-toolbar>
			<v-divider />
			<app-navigation />
		</v-navigation-drawer>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Navigation from '../components/Navigation.vue'
import { mapActions, mapState } from 'vuex'

@Component({
	components: {
		appNavigation: Navigation,
	},
	computed: {
		...mapState(['appName']),
	},
	methods: {
		...mapActions(['retrieveAppName']),
	},
})
export default class Home extends Vue {
	retrieveAppName!: () => void

	logoUrl = `${import.meta.env.VITE_APP_API_PREFIX}/assets/logo`
	salesUrl = `${import.meta.env.VITE_APP_WEB_PREFIX}/web/s`

	drawer = false

	created() {
		this.retrieveAppName()
		this.$vuetify.theme.dark = false
	}

	get userName() {
		return this.$store.state.profile.account.name
	}

	get isSales() {
		if (!this.$store.state.profile.account.roles) {
			return false
		}
		for (const role of this.$store.state.profile.account.roles) {
			console.log('role', role)
			if (role.name === 'Sales') {
				return true
			}
		}
		return false
	}
}
</script>
