<template>
	<crud-component
		title="Casing"
		:crudService="service">
		<tr slot="listing-items" slot-scope="{item}">
			<td>{{item.name}}</td>
			<td>{{item.CasingBase}}</td>
			<td>{{item.CasingCover}}</td>
			<td>{{item.ShankHolder}}</td>
			<td>{{item.ShankDiameter}}</td>
			<td>{{item.UsableLengthMin}}</td>
			<td>{{item.UsableLengthMax}}</td>
		</tr>

		<template slot="new-form" slot-scope="{close, complete}">
			<NewCasing @created="casingCreated(complete)">
				<v-btn @click="close()">Cancel</v-btn>
			</NewCasing>
		</template>
	</crud-component>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { CRUDService, TableHeader, TableRetrieverParams } from '@/components/widgets/models/models'
import axios from 'axios'
import casingService from '@/services/casing-service'
import NewCasing from '@/views/casing/NewCasing.vue'
import { Casing } from '@/models/casing'
import { HttpResponse } from '@/http-client'

class CRUDCasingService implements CRUDService {
	tableHeaders: TableHeader[] = [
		{ text: 'Name', value: 'name' },
		{ text: 'CasingCover', value: 'casingcover' },
		{ text: 'CasingBase', value: 'casingbase' },
		{ text: 'ShankHolder', value: 'shankholder' },
		{ text: 'ShankDiameter', value: 'shankdiameter' },
		{ text: 'UsableLengthMin', value: 'usablelengthmin' },
		{ text: 'UsableLengthMax', value: 'usablelengthmax' },
		// { text: 'Revision', value: 'revision' },
		// { text: 'Remark', value: 'remark' },
		// { text: 'Issue Date', value: 'remark' },
	]

	tableRetriever({ config }: TableRetrieverParams): HttpResponse<any> {
		return casingService.list(config)
	}
}

@Component({
	components: { NewCasing },
})
export default class CasingView extends Vue {
	service = new CRUDCasingService()
	loading = false
	items: Casing[] = []
	private cancelSource = axios.CancelToken.source()

	casingCreated(fn: () => void) {
		fn()

		this.loadCasing()
	}

	private loadCasing() {
		this.loading = true
		casingService
			.list({ cancelToken: this.cancelSource.token })
			.then(r => {
				this.items = r.data
			})
			.catch(reason => {
				// TODO: handle reason
			})
			.finally(() => {
				this.loading = false
			})
	}
}
</script>
<style scoped lang="scss">
</style>
