import { Workflow } from '@/models/Workflow'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DropdownDatasource } from '@/components/widgets/models/models'

class WorkflowService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list(config)
		}
	}

	list(config?: RequestConfig): HttpResponse<Workflow[]> {
		return http.get(`/secured/workflow`, config)
	}

	workflowDetail(workflowID: number, config?: RequestConfig): HttpResponse<Workflow[]> {
		return http.get(`/secured/workflow/${workflowID}`, config)
	}

	create(workflow: Partial<Workflow>, config?: RequestConfig): HttpResponse<Workflow> {
		return http.post('/secured/workflow', workflow, config)
	}
}

export default new WorkflowService()
