<template>
	<div id="v-widget">
		<v-card>
			<v-app-bar color="transparent" flat dense v-if="enableHeader">
				<v-toolbar-title><h4>{{title}}</h4></v-toolbar-title>
				<v-spacer/>
				<slot name="widget-header-action"/>
			</v-app-bar>
			<v-divider v-if="enableHeader"/>
			<v-card-text :class="contentBg">
				<slot name="widget-content"/>
			</v-card-text>
		</v-card>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class VWidget extends Vue {
	@Prop() title: string
	@Prop({ default: true }) enableHeader: boolean
	@Prop({ default: 'white' }) contentBg: string
}
</script>
