<template>
	<div>
		<p class="page-help">
			Please enter the details for Loss or Bonus.
		</p>

		<v-form ref="form">
			<p class="section-divider">
				Loss or Bonus Quantity
			</p>

			<!-- Both to change to combo box once process modelling completed -->
			<dropdown label="LossOrBonus"
								:datasource="lossbonusDs"
								v-model="form.lossbonus"></dropdown>
			<v-text-field label="Qty" required v-model="form.quantity"></v-text-field>
			<v-card-actions>
				<v-spacer></v-spacer>
				<slot></slot>
				<v-btn color="primary" @click="AddLossBonusQty">Add</v-btn>
			</v-card-actions>
		</v-form>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { MvouLossBonus } from '@/models/mvoulossbonus'
import moment from 'moment'

@Component
export default class NewMvouLossBonus extends Vue {
	$refs: {
		form: Vue & { validate(): boolean };
	}

	form: Partial<MvouLossBonus> = {
		creationDate: moment(),
	}
}

</script>
<style scoped lang="scss">
</style>
