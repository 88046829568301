import { NavigationItem } from '@/components/navigation/navigation-model'

const navs: NavigationItem[] = [
	{
		title: 'Account',
		to: { name: 'account' },
		icon: 'person',
	},
	{
		title: 'Role',
		to: { name: 'role' },
		icon: 'group',
	},
	{
		title: 'UI Customization',
		to: { name: 'configUI' },
		icon: 'palette',
	},
	{
		title: 'Settings',
		to: { name: 'settings' },
		icon: 'tune',
	},
]
export default navs
