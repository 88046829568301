import Vue from 'vue'
import moment from 'moment'
import { Formatter } from '@/date/date-format'

Vue.filter('dateFormat', (value: string) => {
	if (!value) {
		return ''
	}
	return Formatter.format(value)
})

Vue.filter('hourFormat', (value: string) => {
	if (!value) {
		return ''
	}
	return Formatter.formatHour(value)
})

Vue.filter('dateTimeFormat', (value: string) => {
	if (!value) {
		return ''
	}
	return Formatter.formatDateTime(value)
})

Vue.filter('capitalize', (value: string) => {
	if (!value) return ''
	let splitStr = value.toLowerCase().split(' ')
	for (let i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
	}
	// Directly return the joined string
	return splitStr.join(' ')
})
