<template>
	<v-container grid-list-md fluid>
		<v-row>
			<h1>
				Job Detail:
				<b>{{job.name}}</b>
			</h1>
			<v-col lg="2" sm="3" cols="12">
				<v-btn color="blue" :href="exportURL" target="_blank">Export</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<h1>Status: {{job.status}}</h1>
		</v-row>
		<v-row>
			<v-col cols="12" sm="6" md="4">
				<v-card class="mx-auto" max-width="600px" outlined>
					<v-card-text>
						<div>
							Product:
							<v-card-title>{{job.productName}}</v-card-title>
						</div>
						<div>
							Quantity:
							<v-card-title>{{job.quantity}}</v-card-title>
						</div>
						<div>
							Customer Quantity:
							<v-card-title>{{job.customerQuantity}}</v-card-title>
						</div>
						<div>
							Completed Date:
							<v-card-title>{{completedDate}}</v-card-title>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-card class="mx-auto" max-width="600px" outlined>
					<v-card-text>
						<div>
							Created Date:
							<v-card-title>{{creationDate}}</v-card-title>
						</div>
						<div>
							Created By:
							<v-card-title>{{job.createdBy}}</v-card-title>
						</div>
						<div>
							Request By:
							<v-card-title>{{job.requestByName}}</v-card-title>
						</div>
						<div>
							Due Date:
							<v-card-title>{{dueDate}}</v-card-title>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-card class="mx-auto" max-width="600px" outlined>
					<v-card-text>
						<div>
							Quotation:
							<v-card-title>{{job.quotation}}</v-card-title>
						</div>
						<div>
							Material Due Date:
							<v-card-title>{{materialDueDate}}</v-card-title>
						</div>
						<div>
							Material Received Date:
							<v-card-title>{{materialReceiveDate}}</v-card-title>
						</div>
						<div>
							<span class="headline">
							<AccessRestricted module="job" :actions="['update']">
								<v-row>
									<v-btn color="secondary" dark v-if="this.jobStatusNew" @click="updateJob()">Start Job</v-btn>
									<v-btn
										color="secondary"
										dark
										v-if="this.jobStatusNew"
										@click="jobDetailDialog=true"
									>Update Job Detail</v-btn>
									<v-btn color="secondary" dark v-if="this.jobStatusNew" @click="deleteJob()">Delete Job</v-btn>
								</v-row>
							</AccessRestricted>
							</span>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-dialog v-model="jobDetailDialog" persistent max-width="600px">
				<v-card>
					<v-form ref="form">
						<div>
							<v-card-title>
								<span class="headline">Job Detail: {{job.name}}</span>
							</v-card-title>
						</div>
						<div>
							<v-card-title>
								<span class="headline">Status: {{job.status}}</span>
							</v-card-title>
						</div>
						<v-card-text>
							<v-container grid-list-md>
								<v-row>
									<v-col cols="12" sm="6" md="4">
										<v-text-field label="Name" v-model="job.name" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field label="Type" v-model="job.typeName" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field label="Product ID" v-model="job.productName" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field label="Quantity" v-model.number="job.quantity" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field label="Request By" v-model="job.requestByName" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<date-picker label="Due Date" v-model="job.dueDate" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field label="Quotation" v-model="job.quotation" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field label="Customer Quantity" v-model.number="job.customerQuantity" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<date-picker label="Material Due Date" v-model="job.materialDueDate" />
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<date-picker label="Material Received Date" v-model="job.materialReceiveDate" />
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-alert type="error" dismissible v-model="alert" />
							<v-btn color="blue darken-1" text @click="jobDetailDialog = false">Close</v-btn>
							<v-btn color="blue darken-1" text @click="updateJobDetail()">Update</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-dialog>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-data-table
					show-expand
					single-expand
					:server-items-length="jobCurrentState.tasks.length"
					:items="jobCurrentState.tasks"
					:headers="headers"
					:hide-default-footer="true"
					style="width: 100%"
					item-key="index"
				>
					<template v-slot:expanded-item="prop">
						<td :colspan="headers.length + 1" style="padding: 0">
							<v-data-table
								:server-items-length="prop.item.progress.length"
								:items="prop.item.progress"
								:headers="header2"
								style="width: 100%"
								:hide-default-footer="true"
							>
								<template v-slot:item.start="{value}">{{value | dateTimeFormat}}</template>
								<template v-slot:item.end="{value}">{{value | dateTimeFormat}}</template>
							</v-data-table>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import {
	TableHeader,
	TableRetrieverParams,
} from '@/components/widgets/models/models'
import taskService from '@/services/task-service'
import planService from '@/services/plan-service'
import router from '../../router'
import { Job } from '@/models/job'
import { Formatter } from '@/date/date-format'
import moment from 'moment'
import { HttpResponse } from '@/http-client'
import jobService from '@/services/job-service'
import ListingWidget from '@/components/widgets/form/ListingWidget.vue'
import { CurrentStatus } from '@/models/currentstatus'
import {
	lossBonusMoveOut,
	MoveIn,
	MoveOut,
	Progress,
	Task,
} from '@/models/task'
import lossbonuslist from '@/services/lossbonus-service'
import { LossBonus } from '@/models/lossbonus'
import AccessRestricted from '@/components/AccessRestricted.vue'

interface LossBonusItem {
	quantity: number
	type: number
}

@Component({
	components: { AccessRestricted },
})
export default class JobDetail extends Vue {
	$refs: {
		taskListing: ListingWidget
		planListing: ListingWidget
	}

	job: Partial<Job> = {}
	jobStatusNew = false
	alert = false

	aggregateId = ''
	creationDate = ''
	dueDate = ''
	completedDate = ''
	jobID = 0
	jobDetailDialog = false
	materialReceiveDate = ''
	materialDueDate = ''

	exportURL = ''
	lossbonus: LossBonus[] = []
	lossBonusItems: LossBonusItem[] = []
	moveInQtyParse: MoveIn = {} as MoveIn
	moveOutQtyParse: MoveOut = {} as MoveOut
	jobName = ''
	moveInQty = 0
	moveOutQty = 0
	expand = false

	jobCurrentState: CurrentStatus = {
		job: {},
		currentTask: {},
		tasks: [] as Task[],
	} as CurrentStatus;

	headers: TableHeader[] = [
		{ text: 'Process', value: 'processName' },
		{ text: 'Quantity', value: 'targetQuantity' },
		{ text: 'Loss Bonus', value: 'lossBonus' },
	]

	header2: TableHeader[] = [
		{ text: 'Start Time', value: 'start' },
		{ text: 'End Time', value: 'end' },
		{ text: 'Quantity', value: 'quantity' },
		{ text: 'Cycle Time', value: 'cycleTime' },
		{ text: 'Remarks', value: 'remarks' },
	]

	created() {
		this.aggregateId = this.$route.params.id

		this.exportURL =
			'http://localhost:8081/secured/job/' + this.aggregateId + '/export'

		jobService.getJob(this.aggregateId).then(resp => {
			this.initWithJob(resp.data)
		})

		lossbonuslist.list().then(v => {
			this.lossbonus = v.data
		})

		jobService.jobCurrentStatus(this.aggregateId).then(v => {
			this.jobCurrentState = v.data
			this.jobName = this.jobCurrentState.job.name
			if (this.jobCurrentState.currentTask) {
				this.moveInQtyParse.quantity = this.jobCurrentState.currentTask.quantity
				this.moveOutQty = this.moveInQtyParse.quantity
				this.moveInQty = this.jobCurrentState.currentTask.quantity
			}
		})
	}

	private initWithJob(job: Job) {
		this.job = job

		this.creationDate = Formatter.format(
			moment(job.dateCreated, 'YYYY-MM-DDTHH:mm:SSZ')
				.toDate()
				.toString(),
		)
		this.dueDate = Formatter.format(
			moment(job.dueDate, 'YYYY-MM-DDTHH:mm:SSZ')
				.toDate()
				.toString(),
		)

		if (job.materialDueDate != null) {
			this.materialDueDate = Formatter.format(
				moment(job.materialDueDate, 'YYYY-MM-DDTHH:mm:SSZ')
					.toDate()
					.toString(),
			)
		} else {
			this.materialDueDate = ''
		}
		if (job.materialReceiveDate != null) {
			this.materialReceiveDate = Formatter.format(
				moment(job.materialReceiveDate, 'YYYY-MM-DDTHH:mm:SSZ')
					.toDate()
					.toString(),
			)
		} else {
			this.materialReceiveDate = ''
		}

		if (job.completedDate != null) {
			this.completedDate = Formatter.format(
				moment(job.completedDate, 'YYYY-MM-DDTHH:mm:SSZ')
					.toDate()
					.toString(),
			)
		} else {
			this.completedDate = ''
		}

		if (job.status === 'NEW') {
			this.jobStatusNew = true
		}

		this.jobID = job.id
	}

	planDataRetriever({ config }: TableRetrieverParams): HttpResponse<any> {
		const id = this.job.id
		if (!id) {
			return Promise.reject(new Error('invalid state'))
		}
		return planService.searchByJobId(id.toString(), config)
	}

	updateJob() {
		const id = this.job.aggregateId ? this.job.aggregateId.toString() : ''
		jobService.startJobFlag(id).then(r => {
			// this.dialog = false;
			this.$router.push({ name: 'crudJob' })
		})
	}

	updateJobDetail() {
		const id = this.job.aggregateId ? this.job.aggregateId.toString() : ''
		jobService.updateJobDetail(id, this.job).then(r => {
			this.$router.push({ name: 'crudJob' })
		})
	}

	deleteJob() {
		const id = this.job.aggregateId ? this.job.aggregateId.toString() : ''
		jobService.deleteJob(id).then(r => {
			// this.dialog = false;
			this.$router.push({ name: 'crudJob' })
		})
	}
}
</script>
