import { Equipment } from '@/models/equipment'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DropdownDatasource } from '@/components/widgets/models/models'

class EquipmentService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list('', '', config)
		}
	}

	list(searchBy: string, searchByKeyword: string, config?: RequestConfig): HttpResponse<Equipment[]> {
		return http.get(`/secured/equipment/list?searchBy=${searchBy}&searchByKeyword=${searchByKeyword}`, config)
	}

	equipmentDetail(ID: string, config?: RequestConfig): HttpResponse<Equipment> {
		return http.get(`/secured/equipment/${ID}`, config)
	}

	createEquipment(
		equipment: Partial<Equipment>,
		config?: RequestConfig,
	): HttpResponse {
		return http.put(`/secured/equipment`, equipment, config)
	}

	updateEquipment(ID: string, color: string, config?: RequestConfig): HttpResponse {
		return http.put(`/secured/equipment/update/${ID}?color=${color}`, config)
	}
}

export default new EquipmentService()
