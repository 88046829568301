<template>
	<v-form ref="form">
		<p class="page-help">
			Please enter the details for the Loss and Bonus you are creating.
		</p>
		<p class="section-divider">
			Loss and Bonus
		</p>

		<v-text-field label="Name" required v-model="form.name"></v-text-field>
		<dropdown label="LossBonusCategory" v-model="form.type"
							:items="['LOSS', 'BONUS']"></dropdown>

		<v-card-actions>
			<v-spacer></v-spacer>
			<slot></slot>
			<v-btn type='submit' color="primary" @click="createLossBonus">Create New</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
	</v-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import dsService from '@/services/datasource-service'
import lossbonusService from '@/services/lossbonus-service'
import { LossBonus } from '@/models/lossbonus'
import moment from 'moment'

@Component
export default class NewLossBonus extends Vue {
	form: Partial<LossBonus> = {}

	createLossBonus() {
		lossbonusService.createLossBonus(this.form)
			.then(r => {
				// this.$emit('created', this.form);
			})
	}
}
</script>

<style scoped lang="scss">
</style>
