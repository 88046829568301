<template>
	<crud-component
		title="Workflow"
		:crudService="service">
		<tr slot="listing-items" slot-scope="{item}">
			<td>{{item.name}}</td>
			<td>{{item.item}}</td>
		</tr>

		<template slot="new-form" slot-scope="{close, complete}">
			<NewWorkflow @created="workflowCreated(complete)">
				<v-btn @click="close()">Cancel</v-btn>
			</NewWorkflow>
		</template>
	</crud-component>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { CRUDService, TableHeader, TableRetrieverParams } from '@/components/widgets/models/models'
import workflowService from '@/services/workflow-service'
import NewWorkflow from '@/views/workflow/NewWorkflow.vue'
import { HttpResponse } from '@/http-client'

class CRUDWorkflowService implements CRUDService {
	tableHeaders: TableHeader[] = [
		{ text: 'Process Name', value: 'name' },
		{ text: 'item', value: 'item' },
	]

	tableRetriever({ config }: TableRetrieverParams): HttpResponse<any> {
		return workflowService.list(config)
	}
}

@Component({
	components: { NewWorkflow },
})
export default class Workflow extends Vue {
	service = new CRUDWorkflowService()

	workflowCreated(fn: () => void) {
		fn()
	}
}
</script>

<style scoped lang="scss">
</style>
