<template>
	<listing
		title="Job"
		:headers="tableHeaders"
		:retriever="tableDataRetriever"
		:search="search">
		<template v-slot:item="{item}">
			<tr @click="clicked({item})">
				<td>{{item.id}}</td>
				<td>{{item.name}}</td>
				<td>{{item.typeName}}</td>
				<td>{{item.productName}}</td>
				<td>{{item.status}}</td>
				<td>{{item.quantity}}</td>
				<td>{{item.dueDate | dateFormat}}</td>
				<td>{{item.completedDate | dateFormat}}</td>
				<td>{{item.creationDate | dateFormat}}</td>
				<td>{{item.createdBy}}</td>
				<td>{{item.requestBy}}</td>
				<td>{{item.quotation}}</td>
				<td>{{item.po}}</td>
				<td>{{item.invoice}}</td>
				<td>{{item.materialReceiveDate}}</td>
			</tr>
		</template>
	</listing>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { TableHeader, TableRetrieverParams } from '@/components/widgets/models/models'
import jobService from '@/services/job-service'
import NewJob from '@/views/job/NewJob.vue'
import router from '../../router'
import { HttpResponse } from '@/http-client'

@Component({
	components: { NewJob },
})
export default class Job extends Vue {
	search = ''

	tableHeaders: TableHeader[] = [
		{ text: 'ID', value: 'id' },
		{ text: 'Name', value: 'name' },
		{ text: 'Type', value: 'type' },
		{ text: 'Product', value: 'product' },
		{ text: 'Status', value: 'status' },
		{ text: 'Quantity', value: 'quantity' },
		{ text: 'Due Date', value: 'dueDate' },
		{ text: 'Completed Date', value: 'completedDate' },
		{ text: 'Created Date', value: 'creationDate' },
		{ text: 'Created By', value: 'createdBy' },
		{ text: 'Request By', value: 'requestBy' },
		{ text: 'Quotation', value: 'quotation' },
		{ text: 'PO', value: 'po' },
		{ text: 'Invoice', value: 'invoice' },
		{ text: 'Material Received Date', value: 'materialReceiveDate' },
	]

	status = ''

	tableDataRetriever({ config }: TableRetrieverParams): HttpResponse<any> {
		const status = router.app.$route.params.status
		let fromDate = router.app.$route.params.fromDate
		let toDate = router.app.$route.params.toDate

		if (!status) {
			if (status === 'new') {
				this.status = ''
			} else {
				this.status = status
			}
		}

		if (!fromDate) {
			fromDate = ''
		}

		if (!toDate) {
			toDate = ''
		}

		return jobService.list(this.status, fromDate, toDate, config)
	}

	clicked(row: any) {
		router.push({ name: 'jobDetail', params: row.item })
	}
}
</script>

<style scoped lang="scss">
</style>
