<template>
	<v-form>
		<p class="page-help">Please enter the details for Move Out.</p>
		<p class="section-divider">Move Out</p>
		<v-form ref="formPopMVOU">
			<v-card-title>
				<span class="headline">Move Out</span>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-row >
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Job Name" required v-model="jobName"/>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Total Qty" required v-model="moveInQty"/>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Loss Qty" required v-model="lossQty"/>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Bonus Qty" required v-model="bonusQty"/>
						</v-col>

						<v-toolbar flat color="white">
							<v-toolbar-title>Loss and Bonus</v-toolbar-title>
							<v-divider vertical class="mx-4"/>
							<v-spacer/>
							<v-card-actions>
								<v-btn text color="blue darken-4" @click="newItem">New</v-btn>
							</v-card-actions>
						</v-toolbar>
						<v-data-table hide-default-header :server-items-length="lossBonusItems.length" :items="lossBonusItems">
							<template v-slot:item="prop">
								<td style="white-space: nowrap; width: 50%">
									<dropdown-label v-model="prop.item.name" :items="lossbonus"/>
								</td>
								<td style="white-space: nowrap; width: 40%">
									<v-text-field label="Qty" required v-model="prop.item.quantity"/>
								</td>
								<td style="white-space: nowrap; width: 1%">
									<v-btn icon @click="deleteItem(prop.index)">
										<v-icon>delete</v-icon>
									</v-btn>
								</td>
							</template>
						</v-data-table>

						{{lossBonusItems}}
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer/>
				<v-btn color="blue darken-1" text @click="dialog1 = false">Close</v-btn>
				<v-btn color="blue darken-1" text @click="createMoveOut()">Move Out</v-btn>
			</v-card-actions>
		</v-form>
	</v-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import mvoulossbonusService from '@/services/mvoulossbonus-service'
import moveoutService from '@/services/mvou-service'
import { MoveOut } from '@/models/moveout'
import { Job } from '@/models/job'
import NewMvouLossBonus from '@/views/MoveOutLossBonus.vue'
import { CRUDService, TableHeader, TableRetrieverParams } from '@/components/widgets/models/models'
import DropdownLabel from '@/components/widgets/form/DropdownLabel.vue'
import { LossBonus } from '@/models/lossbonus'
import { HttpResponse } from '@/http-client'

interface LossBonusItem {
	lossBonusId: number;
	qty: number;
}

class CRUDMvouLossBonusService implements CRUDService {
	tableHeaders: TableHeader[] = [
		{ text: 'LossBonus', value: 'lossbonusname' },
		{ text: 'Qty', value: 'quantity' },
		// { text: 'Date Created', value: 'creationDate' },
		// { text: 'Status', value: 'status' },
		// { text: 'Revision', value: 'revision' },
		// { text: 'Remark', value: 'remark' },
		// { text: 'Issue Date', value: 'remark' },
	]

	tableRetriever({ config }: TableRetrieverParams): HttpResponse<any> {
		return mvoulossbonusService.list(config)
	}
}

@Component({
	components: { NewMvouLossBonus, DropdownLabel },
})
export default class NewMoveOut extends Vue {
	job: Partial<Job> = {}
	service = new CRUDMvouLossBonusService()
	form: Partial<MoveOut> = {}
	lossBonusItems: LossBonusItem[] = []
	lossbonus: LossBonus[] = []
	dialog1 = false
	moveInQty = 0
	bonusQty = 0
	lossQty = 0
	moveOutQty = 0

	createMoveOut() {
		moveoutService.createMoveout(this.form).then(r => {
			this.$emit('created', this.form)
		})
	}

	deleteItem(index: number) {
		this.lossBonusItems.splice(index, 1)
	}

	newItem() {
		this.lossBonusItems.push({ lossBonusId: 0, qty: 0 })
	}
}
</script>

<style scoped lang="scss">
</style>
