<template>
	<v-form>
		<p class="page-help">Please enter the details for Move In.</p>
		<p class="section-divider">Move In</p>

		<v-text-field label="Name" :value="job.name" readonly></v-text-field>
		<v-text-field label="TotalQty" v-model="form.quantity"></v-text-field>
		<v-text-field label="CurrentProcess" v-model="form.name"></v-text-field>

		<v-card-actions>
			<v-spacer></v-spacer>
			<slot></slot>
			<v-btn color="primary" @click="createMoveIn(job.id, form.quantity)">Move In</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
	</v-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { MoveOut } from '@/models/moveout'
import { Job } from '@/models/job'
import NewMvouLossBonus from '@/views/MoveOutLossBonus.vue'
import DropdownLabel from '@/components/widgets/form/DropdownLabel.vue'

@Component({
	components: { NewMvouLossBonus, DropdownLabel },
})
export default class NewMoveIn extends Vue {
	job: Partial<Job> = {}
	form: Partial<MoveOut> = {}

	// createMoveIn(item: number, qty: number, complete: () => void) {
	// 	taskService.moveIn(item, qty).then(r => {
	// 		this.$emit('created', this.form);
	// 	});
	// }
}
</script>

<style scoped lang="scss">
</style>
