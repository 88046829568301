import { Module } from 'vuex'
import { ProfileState, RootState } from '@/store/store-state'
import { CompleteAccount } from '@/models/account'

export const LOGIN = 'login'
export const LOGOUT = 'logout'

const profile: Module<ProfileState, RootState> = {
	state: { account: {} },
	mutations: {
		[LOGIN](state, obj: CompleteAccount) {
			state.account = obj
		},

		[LOGOUT](state) {
			state.account = {}
			document.location.href = `${import.meta.env.VITE_APP_WEB_PREFIX}/web/logout`
		},
	},
}

export default profile
