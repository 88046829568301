import { Plan } from '@/models/plan'
import { http, HttpResponse, RequestConfig } from '@/http-client'

class PlanService {
	list(config?: RequestConfig): HttpResponse<Plan[]> {
		const url = '/secured/plan/list'

		return http.get(url, config)
	}

	searchByJobId(jobID: string, config?: RequestConfig): HttpResponse<Plan[]> {
		const url = `/secured/plan/search?jobid=${jobID}`

		return http.get(url, config)
	}

	create(data: Partial<Plan>, config?: RequestConfig): HttpResponse<Plan> {
		return http.post('/secured/plan', data, config)
	}

	update(id: string, data: Partial<Plan>, config?: RequestConfig): HttpResponse<Plan> {
		return http.put(`/secured/plan/${id}`, data, config)
	}

	delete(id: string, config?: RequestConfig): HttpResponse<Plan> {
		return http.delete(`/secured/plan/${id}`, config)
	}

	searchById(id: string, config?: RequestConfig): HttpResponse<Plan[]> {
		const url = `/secured/plan/${id}`

		return http.get(url, config)
	}
}

export default new PlanService()
