<template>
	<div id="pageDashboard">
		<div id="summary">
			<v-container grid-list-xl fluid>
				<v-row>
					<!-- mini statistic start -->
					<v-col lg="3" sm="6" cols="12" @click="statusSelected('completed')">
						<mini-statistic
							icon="fas fa-check-circle"
							:title="groupedJobs.completed.length"
							sub-title="Jobs Completed"
							color="green"
						>
						</mini-statistic>
					</v-col>
					<v-col lg="3" sm="6" cols="12" @click="statusSelected('new')">
						<mini-statistic
							icon="fas fa-plus-circle"
							:title="groupedJobs.created.length"
							sub-title="Jobs Created"
							color="blue"
						>
						</mini-statistic>
					</v-col>
					<v-col lg="3" sm="6" cols="12" @click="statusSelected('pending')">
						<mini-statistic
							icon="fa fa-info-circle"
							:title="groupedJobs.pending.length"
							sub-title="Jobs Pending"
							color="grey"
						>
						</mini-statistic>
					</v-col>
					<v-col lg="3" sm="6" cols="12" @click="statusSelected('overdue')">
						<mini-statistic
							icon="fa fa-exclamation-circle"
							:title="groupedJobs.overdue.length"
							sub-title="Jobs Overdue"
							color="red darken-1"
						>
						</mini-statistic>
					</v-col>
					<!-- mini statistic  end -->
				</v-row>
			</v-container>
		</div>

		<div>
			<v-container grid-list-xl fluid>
				<v-row>
					<v-col lg="2" sm="3" cols="12">
						<datepicker-widget label="From" :value="fromDate" v-model="newFromDate"> </datepicker-widget>
					</v-col>
					<v-col lg="2" sm="3" cols="12">
						<datepicker-widget label="To" :value="toDate" v-model="newToDate"> </datepicker-widget>
					</v-col>
					<v-col lg="2" sm="3" cols="12">
						<v-btn color="success" @click="getSelectedDates()">Generate</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<div id="chart">
			<v-container grid-list-xl fluid>
				<v-row>
					<v-col lg="12" sm="12" cols="12">
						<v-widget title="Jobs History (1 week)" content-bg="white">
							<v-btn icon slot="widget-header-action">
								<v-icon class="text--secondary">refresh</v-icon>
							</v-btn>
							<div slot="widget-content">
								<chart :options="chartOptions" auto-resize> </chart>
							</div>
						</v-widget>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<style scoped>
.echarts {
	width: 100%;
	height: 400px;
}
</style>

<script lang="ts">
import MiniStatistic from '@/components/widgets/statistic/MiniStatistic.vue'
import VWidget from '@/components/widgets/VWidget.vue'
import { Component, Vue } from 'vue-property-decorator'
import router from '../router'
import service from '@/services/job-service'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/dataset'
import 'echarts/lib/component/tooltip'
import { Job } from '@/models/job'
import moment, { Moment } from 'moment'
import DatepickerWidget from '@/components/widgets/form/DatepickerWidget.vue'

@Component({
	components: {
		miniStatistic: MiniStatistic,
		vWidget: VWidget,
		datepickerWidget: DatepickerWidget,
	},
})
export default class Dashboard extends Vue {
	jobs: Job[] = []

	fromDate: Moment = moment()
		.startOf('day')
		.utcOffset(0, true)
		.subtract(6, 'd')

	toDate: Moment = moment()
		.startOf('day')
		.utcOffset(0, true)

	newFromDate: Moment = this.fromDate
	newToDate: Moment = this.toDate

	groupedJobs: { [name: string]: Job[] } = {
		pending: [],
		completed: [],
		created: [],
		overdue: [],
	}

	mounted() {
		service.listJobsPastWeek().then(r => {
			// TODO: do something
			this.jobs = r.data
			this.groupJobs(r.data)
		})
	}

	statusSelected(status: string) {
		router.push({
			name: 'crudJob2',
			params: {
				searchBy: 'status',
				status: status,
				fromDate: this.newFromDate.format('YYYY-MM-DD'),
				toDate: this.newToDate.format('YYYY-MM-DD'),
			},
		})
	}

	groupJobs(jobs: Job[]) {
		const tomorrow = moment()
			.startOf('day')
			.utcOffset(0, true)
			.add(1, 'd')
		const today = moment()
			.startOf('day')
			.utcOffset(0, true)
		const yesterday = moment()
			.startOf('day')
			.utcOffset(0, true)
			.subtract(1, 'd')

		for (const job of jobs) {
			const status = job.status
			const dueDate = moment(job.dueDate)

			let strCreationDate = job.dateCreated.toString()
			strCreationDate = strCreationDate.replace('Z', '')
			let creationDate = new Date(strCreationDate)
			const dateCreated = moment(creationDate)

			if (status === 'COMPLETED') {
				this.groupedJobs.completed.push(job)
			} else if (dueDate.isBefore(today)) {
				this.groupedJobs.overdue.push(job)
			}

			if (status === 'PENDING') {
				this.groupedJobs.pending.push(job)
			}

			if (dateCreated.isBetween(this.newFromDate, this.newToDate, 'd', '[]')) {
				this.groupedJobs.created.push(job)
			}
		}
	}

	get chartOptions() {
		// subtract 6 days, so we get 1 week of data
		const date = moment()
			.startOf('day')
			.utcOffset(0, true)
			.subtract(6, 'd')

		const week = []
		const completed = []
		const created = []

		for (let i = 0; i < 7; i++) {
			const weekLabel = date.add(i, 'day').format('LL')
			week.push(weekLabel)

			completed.push(this.groupedJobs.completed.filter(v => date.isSame(v.completedDate, 'day')).length)

			console.log(this.jobs)
			created.push(this.jobs.filter(v => date.isSame(v.dateCreated, 'day')).length)

			date.subtract(i, 'day')
		}

		return {
			legend: {
				data: ['Completed', 'Created'],
			},
			tooltip: {
				show: true,
			},
			xAxis: {
				data: week,
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					name: 'Completed',
					type: 'line',
					data: completed,
					itemStyle: {
						color: 'green',
					},
				},
				{
					name: 'Created',
					type: 'line',
					data: created,
					itemStyle: {
						color: 'blue',
					},
				},
			],
		}
	}

	getSelectedDates() {
		this.groupedJobs = {
			pending: [],
			completed: [],
			created: [],
			overdue: [],
		}

		service.list('', this.newFromDate.format('YYYY-MM-DD'), this.newToDate.format('YYYY-MM-DD')).then(r => {
			// TODO: do something
			this.jobs = r.data
			this.groupJobs(r.data)
		})
	}
}
</script>
