import { http, HttpResponse, RequestConfig } from '@/http-client'

class Service {
	updateApplicationName(name: string, config?: RequestConfig): HttpResponse {
		return http.post(`/secured/admin/ui/applicationName`, { name })
	}

	uploadAppLogo(file: Blob, config?: RequestConfig): HttpResponse {
		const form = new FormData()
		form.set('file', file)

		return http.post(`/secured/admin/ui/logo`, form, config)
	}

	uploadFavicon(file: Blob, config?: RequestConfig): HttpResponse {
		const form = new FormData()
		form.set('file', file)

		return http.post(`/secured/admin/ui/favicon`, form, config)
	}

	applicationName(config?: RequestConfig): HttpResponse<string> {
		return http.get('/ui/applicationName', config)
	}
}

export default new Service()
