import { Master, MasterTask } from '@/models/master'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DropdownDatasource } from '@/components/widgets/models/models'

class MasterService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list('', '', config)
		}
	}

	list(searchBy: string, searchByKeyword: string, config?: RequestConfig): HttpResponse<Master[]> {
		return http.get(`/secured/master/list?searchBy=${searchBy}&searchByKeyword=${searchByKeyword}`, config)
	}

	masterDetail(ID: string, config?: RequestConfig): HttpResponse<Master> {
		return http.get(`/secured/master/${ID}`, config)
	}

	createMaster(
		master: Partial<Master>,
		config?: RequestConfig,
	): HttpResponse {
		return http.put(`/secured/master`, master, config)
	}

	updateMaster(ID: string, remark: string, config?: RequestConfig): HttpResponse {
		return http.put(`/secured/master/update/${ID}?remark=${remark}`, config)
	}

	updateMasterFromTask(
		aggregateId: string,
		quantity: MasterTask,
		config?: RequestConfig,
	): HttpResponse {
		return http.post(`/secured/job/${aggregateId}/master`, quantity, config)
	}
}

export default new MasterService()
