import { Product, ProductList } from '@/models/product'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DropdownDatasource } from '@/components/widgets/models/models'

class ProductService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list('', '', config)
		}
	}

	AutoCompleteList(): DropdownDatasource {
		return ({ config }) => {
			return this.list('', '', config)
		}
	}

	list(searchBy: string, searchByKeyword: string, config?: RequestConfig): HttpResponse<Product[]> {
		return http.get(`/secured/product/list?searchBy=${searchBy}&searchByKeyword=${searchByKeyword}`, config)
	}

	productDetail(ID: number | string, config?: RequestConfig): HttpResponse<Product> {
		return http.get(`/secured/product/${ID}`, config)
	}

	createProduct(
		product: Partial<Product>,
		config?: RequestConfig,
	): HttpResponse {
		return http.post(`/secured/product`, product, config)
	}

	updateProduct(
		ID: number | string,
		product: Partial<Product>,
		config?: RequestConfig,
	): HttpResponse {
		return http.put(`/secured/product/${ID}`, product, config)
	}

	reviseProduct(
		ID: number,
		product: Partial<Product>,
		config?: RequestConfig,
	): HttpResponse {
		return http.put(`/secured/product/revise/${ID}`, product, config)
	}
}

export default new ProductService()
