import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import VuexPersistence from 'vuex-persist'
import ProfileModule from './profile-store'
import { RootState } from '@/store/store-state'
import uiService from '@/services/ui-service'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence<RootState>({
	storage: window.localStorage,
})

export default new Vuex.Store<RootState>({
	state: {
		appName: 'Factory Automation System',
	},
	mutations: {
		updateAppName(state, payload: { name: string }) {
			state.appName = payload.name
		},
	},
	actions: {
		async retrieveAppName(context) {
			try {
				const resp = await uiService.applicationName()
				context.commit('updateAppName', { name: resp.data })
			} catch (e) {
				// TODO: handle error
			}
		},
	},
	modules: {
		profile: ProfileModule as Module<any, any>,
	},
})
