<template>
	<v-container grid-list-md>
		<h1>Task Detail</h1>
		<v-row>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Task ID" :value="task.id" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Plan Usage Date" :value="equipmentUsageDate" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Status" :value="task.status" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Sequence" :value="task.sequence" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Activated" :value="task.activated" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Run By" :value="task.runBy" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Plan ID" :value="task.planID" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Process ID" :value="task.processID" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Process Name" :value="task.processName" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Quantity" :value="task.quantity" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Move In Date" :value="moveInDatetime" readonly/>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-text-field label="Move Out Date" :value="moveOutDatetime" readonly/>
			</v-col>
		</v-row>

		<v-row>
			<listing title="Job Progress Listing"
							 :headers="tableHeaders"
							 :retriever="tableDataRetriever">
				<template v-slot:item="{item}">
					<tr>
						<td>{{item.id}}</td>
						<td>{{item.startTime | hourFormat}}</td>
						<td>{{item.endTime | hourFormat}}</td>
						<td>{{item.targetQuantity}}</td>
					</tr>
				</template>
			</listing>
		</v-row>
	</v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { TableHeader, TableRetrieverParams } from '@/components/widgets/models/models'
import jobService from '@/services/job-service'
import router from '../../router'
import { Task } from '@/models/task'
import { Formatter } from '@/date/date-format'
import moment from 'moment'
import { HttpResponse } from '@/http-client'

@Component({
	components: {},
})
export default class TaskDetail extends Vue {
	task: Partial<Task> = {}

	tableHeaders: TableHeader[] = [
		{ text: 'ID', value: 'id' },
		{ text: 'Start Time', value: 'startTime' },
		{ text: 'End Time', value: 'endTime' },
		{ text: 'Target Quantity', value: 'targetQuantity' },
	]

	equipmentUsageDate = ''
	moveInDatetime = ''
	moveOutDatetime = ''

	created() {
		this.task = router.app.$route.params as any

		this.equipmentUsageDate = Formatter.format(moment(this.task.equipmentUsageDate, 'YYYY-MM-DD').toDate().toString())
		this.moveInDatetime = Formatter.format(moment(this.task.moveInDatetime, 'YYYY-MM-DDTHH:mm:SSZ').toDate().toString())
		this.moveOutDatetime = Formatter.format(moment(this.task.moveOutDatetime, 'YYYY-MM-DDTHH:mm:SSZ').toDate().toString())
	}

	tableDataRetriever({ config }: TableRetrieverParams): HttpResponse<any> {
		const id = this.task.id ? this.task.id : 0
		return jobService.jobProgressListByID(id, config)
	}
}
</script>
