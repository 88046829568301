<template>
	<div>
		<!-- listing Component -->
		<listing
			ref="listing"
			:title="title"
			:retriever="crudService.tableRetriever"
			:headers="crudService.tableHeaders">
			<template v-slot:actions v-if="enableButton">
				<v-btn text color="blue darken-4" @click="newClicked">New</v-btn>
			</template>
			<template v-slot:item="props">
				<slot name="listing-items" v-bind="props"/>
			</template>
		</listing>

		<!-- Create Component -->
		<v-dialog v-model="dialog" max-width="1000px" :persistent="true">
			<v-card>
				<v-toolbar dark color="primary">
					<v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
					<v-spacer/>
					<v-btn icon @click="dialog = false">
						<v-icon>close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-card-text v-if="dialog">
					<!-- create / update form goes here -->
					<slot name="new-form"
								:close="closeDialog" :complete="completeDialog"
								v-if="shownForm === 'new'"/>
					<slot name="update-form"
								:close="closeDialog"
								:complete="completeDialog"
								v-else-if="shownForm === 'update'"/>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CRUDService } from './widgets/models/models'
import ListingWidget from '@/components/widgets/form/ListingWidget.vue'

@Component({
	components: {
		Vnodes: {
			functional: true,
			render: (h: any, ctx: any) => ctx.props.vnodes,
		},
	},
})
export default class CRUDComponent extends Vue {
	$refs: {
		listing: ListingWidget;
	}

	@Prop({ default: '' })
	title: string

	@Prop({ required: true })
	crudService: CRUDService

	dialog = false

	@Prop({ default: true }) enableButton: boolean

	shownForm: 'update' | 'new' = 'new'

	newClicked() {
		this.dialog = true
		this.shownForm = 'new'
	}

	// closes the dialog, and also reloads the listing
	completeDialog() {
		this.closeDialog()
		this.$refs.listing.refresh()
	}

	closeDialog() {
		this.dialog = false
	}

	updateClicked() {
		this.dialog = true
		this.shownForm = 'update'
	}

	@Watch('dialog')
	watchDialog() {
		if (!this.dialog) {
			this.shownForm = 'new'
		}
	}

	get dialogTitle() {
		switch (this.shownForm) {
			case 'new':
				return `New ${this.title}`
			case 'update':
				return `Update ${this.title}`
		}
	}

	// reloads the listing
	refreshListing() {
		this.$refs.listing.refresh()
	}
}
</script>

<style scoped lang="scss">
</style>
