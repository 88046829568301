interface DataSourceType {
	[key: string]: { title: string };
}

export const SupportedDataSource: DataSourceType = {
	standardFamily: { title: 'Standard Family' },
	toolFamily: { title: 'Tool Family' },
	toolType: { title: 'Tool Type' },
	jobType: { title: 'Job Type' },
	machine: { title: 'Machine' },
	employee: { title: 'Employee' },
	process: { title: 'Process' },
	customer: { title: 'Customer' },
}
