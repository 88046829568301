<template>
	<div @click="clicked" class="editable-container">
		<template v-if="editing">
			<v-select :items="items" :autofocus="true"
								:item-text="itemText" :item-value="itemValue"
								v-model="internalValue"
								@blur="cancel"
								@keyup.esc="cancel"
								@input="valueChanged"></v-select>
		</template>
		<template v-else>
			<div class="text-container">
				{{ displayValue }}
			</div>
		</template>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DropdownLabel extends Vue {
	@Prop({ default: '' })
	value: string

	@Prop({ default: false })
	editMode: boolean

	@Prop({ default: () => [] })
	items: any[]

	@Prop({ default: 'name' })
	itemText: string

	@Prop({ default: 'id' })
	itemValue: string

	editing = false

	internalValue = ''

	cancelTimeout = 0

	@Watch('value', { immediate: true })
	onValueChange() {
		this.internalValue = this.value
		this.$emit('input', this.internalValue)
	}

	@Watch('editMode', { immediate: true })
	onEditModeChange() {
		this.editing = this.editMode
	}

	clicked() {
		this.editing = true
	}

	valueChanged() {
		this.disableEditing()
		this.$emit('input', this.internalValue)
	}

	cancel() {
		// check if editing, as blur will be called after selection is made
		if (this.editing) {
			this.$emit('cancel')
		}
	}

	get displayValue() {
		const item = this.items.find(v => v[this.itemValue] === this.internalValue)
		return item ? item[this.itemText] : ''
	}

	private disableEditing() {
		this.editing = false
	}
}
</script>

<style scoped lang="scss">
	.editable-container {
		width: 100%;
		height: inherit;
		display: table;
	}

	.text-container {
		display: table-cell;
		vertical-align: middle;
	}
</style>
