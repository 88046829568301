import { http, HttpResponse, RequestConfig } from '@/http-client'
import { Credentials } from '@/models/authentication'
import store from '@/store/store'
import { LOGIN, LOGOUT } from '@/store/profile-store'
import { CompleteAccount } from '@/models/account'

class AuthenticationService {
	login(credentials: Credentials, config?: RequestConfig): HttpResponse<CompleteAccount> {
		return http.post<CompleteAccount>('/auth/login', credentials, config).then(v => {
			// logged in successfully, save to store
			store.commit(LOGIN, v.data)
			return Promise.resolve(v)
		})
	}

	logout(config?: RequestConfig): HttpResponse<any> {
		return new Promise((resolve, reject) => {
			store.commit(LOGOUT)
			resolve(http.post('/auth/logout', null, config))
		})
	}

	currentSession(config?: RequestConfig): HttpResponse<CompleteAccount> {
		return http.get('/web/authenticated', config)
	}
}

export default new AuthenticationService()
