import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
	icons: {
		iconfont: 'md',
	},
	themes: {
		light: {
			primary: '#1976D2',
			secondary: '676C70',
			accent: '#85B3CB',
			error: '#FF5252',
			info: '#2196F3',
			success: '#4CAF50',
			warning: '#FFC107',
		},
	},
})
