import { LossBonus } from '@/models/lossbonus'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DropdownDatasource } from '@/components/widgets/models/models'

class LossBonusService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list(config)
		}
	}

	list(config?: RequestConfig): HttpResponse<LossBonus[]> {
		return http.get(`/secured/lossbonus`, config)
	}

	createLossBonus(
		lossbonus: Partial<LossBonus>,
		config?: RequestConfig,
	): HttpResponse {
		return http.post(`/secured/lossbonus`, lossbonus, config)
	}

	deleteItem(
		LossBonusId: number,
		itemId: number,
		config?: RequestConfig,
	): HttpResponse<LossBonus> {
		return http.delete(`/secured/lossbonus/${LossBonusId}`, config)
	}

	listItems(
		type: string,
		config?: RequestConfig,
	): HttpResponse<LossBonus[]> {
		return this.list(config).then(r => {
			const newResponse = Object.assign({}, r, { data: r.data })
			return Promise.resolve(newResponse)
		})
	}
}

export default new LossBonusService()
