<template>
	<v-list>
		<v-list-item :to="{ name: 'dashboard' }">
			<v-list-item-action>
				<v-icon>dashboard</v-icon>
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title>Dashboard</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<AccessRestricted module="admin">
			<v-list-group no-action prepend-icon="tune">
				<template v-slot:activator>
					<v-list-item-title>Admin</v-list-item-title>
				</template>

				<DrawerNavigationItem v-for="(v, idx) in adminItems" :value="v" :key="idx" />
			</v-list-group>
		</AccessRestricted>
		<AccessRestricted module="job" :actions="['read']">
			<v-list-item :to="{ name: 'crudJob' }">
				<v-list-item-action>
					<v-icon>assignment</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Job</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</AccessRestricted>
		<AccessRestricted module="workflow" :actions="['read']">
			<v-list-item :to="{ name: 'workflow' }">
				<v-list-item-action>
					<v-icon>assignment</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Workflow</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</AccessRestricted>
		<AccessRestricted module="product" :actions="['read']">
			<v-list-item :to="{ name: 'product' }">
				<v-list-item-action>
					<v-icon>list</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Product</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</AccessRestricted>
		<AccessRestricted module="master" :actions="['read']">
			<v-list-item :to="{ name: 'master' }">
				<v-list-item-action>
					<v-icon>list</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Master</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</AccessRestricted>
		<AccessRestricted module="job execution" :actions="['read']">
			<v-list-item :to="{ name: 'task' }">
				<v-list-item-action>
					<v-icon>add</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Task</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</AccessRestricted>
		<AccessRestricted module="equipment" :actions="['read']">
			<v-list-item :to="{ name: 'equipment' }">
				<v-list-item-action>
					<v-icon>add</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>Equipment</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</AccessRestricted>

		<AccessRestricted module="report" :actions="['read']">
			<v-list-group :value="false" no-action prepend-icon="list">
				<template v-slot:activator>
					<v-list-item-title>Reports</v-list-item-title>
				</template>

				<v-list-item :to="{ name: 'productReport' }">
					<v-list-item-content>
						<v-list-item-title>Product</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :to="{ name: 'jobReport' }">
					<v-list-item-content>
						<v-list-item-title>Job</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-group>
		</AccessRestricted>

		<AccessRestricted module="datasource" :actions="['read']">
			<v-list-group :value="false" no-action prepend-icon="list">
				<template v-slot:activator>
					<v-list-item-title>Data Sources</v-list-item-title>
				</template>

				<v-list-item v-for="(value, key) in dataSources" :key="key" :to="{ name: 'datasource', params: { type: key } }">
					<v-list-item-content>
						<v-list-item-title>{{ value.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'casing' }">
					<v-list-item-content>
						<v-list-item-title>Casing</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :to="{ name: 'lossbonus' }">
					<v-list-item-content>
						<v-list-item-title>LossBonus</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-group>
		</AccessRestricted>

		<AccessRestricted module="legacy" :actions="['read']">
			<v-list-group :value="false" no-action prepend-icon="history">
				<template v-slot:activator>
					<v-list-item-title>Legacy Items</v-list-item-title>
				</template>
				<AccessRestricted module="legacy" :actions="['import']">
					<v-list-item :to="{ name: 'legacyImport' }">
						<v-list-item-content>
							<v-list-item-title>Import Legacy DB</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</AccessRestricted>
				<v-list-item :to="{ name: 'legacyDatasource' }">
					<v-list-item-content>
						<v-list-item-title>Legacy Datasource</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :to="{ name: 'legacyProduct' }">
					<v-list-item-content>
						<v-list-item-title>Legacy Product</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :to="{ name: 'legacyMaster' }">
					<v-list-item-content>
						<v-list-item-title>Legacy Master</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :to="{ name: 'legacyJob' }">
					<v-list-item-content>
						<v-list-item-title>Legacy Job</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-group>
		</AccessRestricted>

		<v-divider class="mt-2 mb-2" />
		<v-list-item @click="logout" exact>
			<v-list-item-action>
				<v-icon>settings</v-icon>
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title>Logout</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { SupportedDataSource } from '@/views/data-sources/supported-datasource'
import authenticationService from '@/services/authentication-service'
import DrawerNavigationItem from '@/components/navigation/DrawerNavigationItem.vue'
import adminNavs from '@/navigation/admin-nav'
import AccessRestricted from '@/components/AccessRestricted.vue'

@Component({
	components: { AccessRestricted, DrawerNavigationItem },
})
export default class Navigation extends Vue {
	adminItems = adminNavs

	get dataSources() {
		return SupportedDataSource
	}

	logout() {
		authenticationService.logout().finally(() => {
			this.$router.push({ name: 'login' })
		})
	}
}
</script>

<style scoped></style>
