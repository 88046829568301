<template>
	<div>
		<p class="page-help">Please enter the details for the Process you are creating.</p>

		<v-form ref="form">
			<p class="section-divider">Process Information</p>

			<!-- Both to change to combo box once process modelling completed -->
			<v-text-field label="Process Name" required v-model="form.processname"></v-text-field>
			<v-text-field label="Sequence" required v-model="form.sequence"></v-text-field>
			<v-card-actions>
				<v-spacer></v-spacer>
				<slot></slot>
				<v-btn color="primary" @click="AddProcess">Add</v-btn>
			</v-card-actions>
		</v-form>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Process } from '@/models/Process'
import moment from 'moment'

@Component
export default class NewProcess extends Vue {
	$refs: {
		form: Vue & { validate(): boolean };
	}

	form: Partial<Process> = {
		creationDate: moment(),
	}
}
</script>

<style scoped lang="scss">
</style>
