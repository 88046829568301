<template>
	<div>
		<p class="page-help">
			Please enter the details for the casing you are creating.
		</p>

		<v-form ref="form">
			<p class="section-divider">
				Casing Information
			</p>
			<v-text-field label="Name" required v-model="form.name"></v-text-field>
			<date-picker label="Date Created"
									 v-model="form.creationDate"></date-picker>

			<p class="section-divider">
				Casing Specifications
			</p>

			<dropdown label="CasingBase"
								:datasource="casingBaseDs"
								v-model="form.casingBase"></dropdown>
			<dropdown label="CasingCover"
								:datasource="casingCoverDs"
								v-model="form.casingCover"></dropdown>
			<dropdown label="Shank Holding" v-model="form.shankholding"
								value="True"
								:items="['True', 'False','NA']"></dropdown>

			<div>
				<div class="sub-section">Diameter Details</div>
				<v-text-field type="number" label="Shank Diameter"
											v-model.number="form.shankdiameter"></v-text-field>
				<v-text-field type="number" label="Usage Length MIN"
											v-model.number="form.usablelengthmin"></v-text-field>
				<v-text-field type="number"
											label="Usable Length Max"
											v-model.number="form.usablelengthmax"></v-text-field>
			</div>

			<v-card-actions>
				<v-spacer></v-spacer>
				<slot></slot>
				<v-btn color="primary" @click="createCasing">Create Casing</v-btn>
			</v-card-actions>
		</v-form>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import dsService from '@/services/datasource-service'
import casingService from '@/services/casing-service'
import { Casing } from '@/models/casing'
import moment from 'moment'

@Component
export default class NewCasing extends Vue {
	$refs: {
		form: Vue & { validate(): boolean };
	}

	form: Partial<Casing> = {
		creationDate: moment(),
	}

	createCasing() {
		// if (this.$refs.form.validate()) {
		// show loading
		casingService
			.createCasing(this.form)
			.then(res => {
				// completed
				this.$emit('created', this.form)
			})
			.catch(reason => {
				// TODO: handle failure reason
			})
		// }
	}

	get casingCoverDs() {
		return dsService.dropdownDatasource('casingCover')
	}

	get casingBaseDs() {
		return dsService.dropdownDatasource('casingBase')
	}
}
</script>

<style scoped lang="scss">
</style>
