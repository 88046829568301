<template>
	<router-view />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import authenticationService from '@/services/authentication-service'
import { LOGIN } from '@/store/profile-store'

@Component
export default class App extends Vue {
	created() {
		// check if user is logged in, if logged in, then set the state into vuex
		authenticationService
			.currentSession()
			.then(resp => {
				// has data, store into vuex
				this.$store.commit(LOGIN, resp.data)
				if (this.$router.currentRoute.path === '/') {
					this.$router.replace({ name: 'dashboard' })
				}
			})
			.catch(_ => {
				// failed, redirect to login
				window.location.href = `${import.meta.env.VITE_APP_WEB_PREFIX}/web/login`
			})
	}
}
</script>
