import { MoveOut } from '@/models/moveout'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DropdownDatasource } from '@/components/widgets/models/models'

class MoveOutService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list(config)
		}
	}

	list(config?: RequestConfig): HttpResponse<MoveOut[]> {
		return http.get(`/secured/moveout/list`, config)
	}

	createMoveout(
		moveout: Partial<MoveOut>,
		config?: RequestConfig,
	): HttpResponse {
		return http.put(`/secured/MoveOut`, moveout, config)
	}
}

export default new MoveOutService()
