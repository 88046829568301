<template>
	<v-list-item
		:to="value.to">
		<v-list-item-content>
			<v-list-item-title>{{value.title}}</v-list-item-title>
		</v-list-item-content>
		<v-list-item-action v-if="value.icon">
			<v-icon>{{value.icon}}</v-icon>
		</v-list-item-action>
	</v-list-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavigationItem } from './navigation-model'

@Component
export default class DrawerNavigationItem extends Vue {
	@Prop()
	value: NavigationItem
}
</script>

<style scoped lang="scss">

</style>
