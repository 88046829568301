<template>
	<div @click="clicked" class="editable-container">
		<template v-if="editing">
			<v-text-field ref="input" autofocus
										@blur="cancel"
										@keyup.enter="submit"
										@keyup.esc="cancel"
										v-model="internalValue"
										@input="inputChanged"></v-text-field>
		</template>
		<template v-else>
			<div class="text-container">
				{{ internalValue }}

			</div>
		</template>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class EditableLabel extends Vue {
	@Prop({ default: '' })
	value: string

	@Prop({ default: false })
	editMode: boolean

	editing = false

	internalValue = ''

	private submitting = false
	private cancelling = false

	@Watch('value', { immediate: true })
	onValueChange() {
		this.internalValue = this.value
		this.inputChanged()
	}

	@Watch('editMode', { immediate: true })
	onEditModeChange() {
		this.editing = this.editMode
	}

	clicked() {
		this.editing = true
	}

	submit() {
		this.disableEditing()
		this.$emit('submit', this.internalValue)
		this.submitting = true
		setTimeout(() => {
			this.submitting = false
		}, 100)
	}

	cancel() {
		if (this.submitting || this.cancelling) {
			return
		}

		this.disableEditing()
		this.$emit('cancel', this.internalValue)
		this.cancelling = true
		setTimeout(() => {
			this.cancelling = false
		}, 100)
	}

	inputChanged() {
		this.$emit('input', this.internalValue)
	}

	private disableEditing() {
		this.editing = false
	}
}
</script>

<style scoped lang="scss">
	.editable-container {
		width: 100%;
		height: inherit;
		display: table;
	}

	.text-container {
		display: table-cell;
		vertical-align: middle;
	}
</style>
