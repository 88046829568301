<template>
	<crud-component title="LossBonus" :crudService="service">
		<tr slot="listing-items" slot-scope="{item}">
			<td>{{item.name}}</td>
			<td>{{item.type}}</td>
			<td style="white-space: nowrap; width: 1%">
				<v-btn icon @click="deleteItem(item.id)">
					<v-icon>delete</v-icon>
				</v-btn>
			</td>
		</tr>

		<template slot="new-form" slot-scope="{close, complete}">
			<NewLossBonus @created="lossBonusCreated(complete)">
				<v-btn @click="close()">Cancel</v-btn>
			</NewLossBonus>
		</template>
	</crud-component>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { CRUDService, TableHeader, TableRetrieverParams } from '@/components/widgets/models/models'
import LossBonusService from '@/services/lossbonus-service'
import NewLossBonus from '@/views/lossbonus/NewLossBonus.vue'
import { LossBonus } from '@/models/lossbonus'
import { HttpResponse } from '@/http-client'

class CRUDLossBonusService implements CRUDService {
	tableHeaders: TableHeader[] = [
		{ text: 'Name', value: 'name' },
		{ text: 'LossBonusCategory', value: 'type' },
	]

	tableRetriever({ config }: TableRetrieverParams): HttpResponse<any> {
		return LossBonusService.list(config)
	}
}

@Component({
	components: { NewLossBonus },
})
export default class LossBonusListing extends Vue {
	service = new CRUDLossBonusService()

	LossBonus: LossBonus[] = []

	lossBonusCreated(fn: () => void) {
		fn()
	}

	deleteItem(item: number, complete: () => void) {
		LossBonusService.deleteItem(item, item).then(r => {
		})
	}
}
</script>

<style scoped lang="scss">
</style>
