import { Casing } from '@/models/casing'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DropdownDatasource } from '@/components/widgets/models/models'

class CasingService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list(config)
		}
	}

	list(config?: RequestConfig): HttpResponse<Casing[]> {
		return http.get(`/secured/casing/list`, config)
	}

	createCasing(
		casing: Partial<Casing>,
		config?: RequestConfig,
	): HttpResponse {
		return http.put(`/secured/casing`, casing, config)
	}
}

export default new CasingService()
