<template>
	<v-container grid-list-md>
		<h1>
			Job Detail:
			<B>{{jobCurrentState.job.name}}</B>
		</h1>
		<v-form ref="form">
			<v-row>
				<v-col cols="12" sm="6" md="3">
					<v-card class="mx-auto" max-width="600px" outlined>
						<v-card-text>
							<div>
								Product:
								<v-card-title>{{jobCurrentState.productName}}</v-card-title>
							</div>
							<div>Type:</div>
							<v-card-title>{{jobCurrentState.typeName}}</v-card-title>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="3">
					<v-card class="mx-auto" max-width="600px" outlined>
						<v-card-text>
							<div>
								Due Date:
								<v-card-title>{{jobDueDate}}</v-card-title>
							</div>
							<div>
								Request By:
								<v-card-title>{{jobCurrentState.requestByName}}</v-card-title>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="3">
					<v-card class="mx-auto" max-width="600px" outlined>
						<v-card-text>
							<div>
								Status:
								<v-card-title>{{jobCurrentState.job.status}}</v-card-title>
							</div>
							<div>
								Quantity:
								<v-card-title>{{jobCurrentState.job.quantity}}</v-card-title>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="6" md="3">
					<v-card class="mx-auto" max-width="600px" height="100%" outlined>
						<v-card-text>
							<div>
								Current Process:
								<v-card-title>{{jobCurrentState.currentTask.processName}}</v-card-title>
							</div>
						</v-card-text>
						<AccessRestricted module="job execution" :actions="['move in']">
							<v-dialog v-model="dialog" persistent max-width="600px" v-if="jobCurrentState.moveIn">
								<template v-slot:activator="{on}">
									<v-card-actions align="left">
										<v-spacer></v-spacer>
										<v-btn v-on="on" color="secondary" dark>MOVE IN</v-btn>
									</v-card-actions>
								</template>
								<v-card>
									<v-form ref="form">
										<v-card-title>
											<span class="headline">Move In</span>
										</v-card-title>
										<v-card-text>
											<v-container grid-list-md>
												<v-row>
													<v-col cols="12" sm="6" md="4">
														<dropdown label="Equipment" v-model="moveInQtyParse.equipmentId" :datasource="equipmentDatasource" />
													</v-col>
												</v-row>
											</v-container>
										</v-card-text>
										<v-card-actions>
											<v-spacer />
											<v-alert
												type="error"
												dismissible
												v-model="alertMoveIn"
											>Move In Quantity cannot more then Planned Quantity</v-alert>
											<v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
											<v-btn
												color="blue darken-1"
												text
												@click="createMoveIn(aggregateId, moveInQtyParse.quantity)"
											>Move In</v-btn>
										</v-card-actions>
									</v-form>
								</v-card>
							</v-dialog>
						</AccessRestricted>
						<AccessRestricted module="job execution" :actions="['move out']">
							<v-dialog v-model="dialog1" persistent max-width="600px" v-if="jobCurrentState.moveOut">
								<template v-slot:activator="{on}">
									<v-card-actions align="left">
										<v-spacer></v-spacer>
										<v-btn v-on="on" color="secondary" dark>MOVE OUT</v-btn>
									</v-card-actions>
								</template>

								<v-card>
									<v-form ref="form">
										<v-card-title>
											<span class="headline">Move Out - {{jobName}}</span>
										</v-card-title>
										<v-card-text>
											<v-container grid-list-md>
												<v-row>
													<v-col cols="12" sm="6" md="4">
														<v-card-text>Loss Qty:</v-card-text>
														<div>
														<v-text-field type="number" label="Total Qty" v-model.number="moveInQty" />
														</div>
													</v-col>
													<v-col cols="12" sm="6" md="4">
														<v-card-text>Loss Qty:</v-card-text>
														<v-card-title>{{totalLoss}}</v-card-title>
													</v-col>
													<v-col cols="12" sm="6" md="4">
														<v-card-text>Bonus Qty:</v-card-text>
														<v-card-title>{{totalBonus}}</v-card-title>
													</v-col>
													<v-col cols="12" sm="6" md="4">
														<v-text-field label="Cycle Time" v-model="cycleTime" />
													</v-col>
													<v-col cols="12" sm="6" md="4">
														<v-text-field label="Remarks" v-model="remarks" />
													</v-col>
													<v-toolbar flat color="white">
														<v-toolbar-title>Loss/Bonus</v-toolbar-title>
														<v-divider vertical class="mx-4" />
														<v-spacer />
														<v-card-actions>
															<v-btn text color="blue darken-4" @click="newItem">New</v-btn>
														</v-card-actions>
													</v-toolbar>
													<v-data-table
														:server-items-length="lossBonusItems.length"
														:items="lossBonusItems"
														:hide-default-footer="true"
													>
														<template v-slot:item="prop">
															<tr>
															<td style="width: 50%">
																<dropdown
																	label="Loss Bonus"
																	v-model="prop.item.type"
																	:items="lossbonus"
																	:editmode="prop.item.new"
																/>
															</td>
															<td style="white-space: nowrap; width: 40%">
																<v-text-field
																	label="Qty"
																	required
																	v-model.number="prop.item.quantity"
																	type="number"
																/>
															</td>
															<td style="white-space: nowrap; width: 1%">
																<v-btn icon @click="deleteItem(prop.index)">
																	<v-icon>delete</v-icon>
																</v-btn>
															</td>
															</tr>
														</template>
													</v-data-table>
												</v-row>
											</v-container>
										</v-card-text>
										<v-card-actions>
											<v-spacer />
											<v-alert
												type="error"
												dismissible
												v-model="alert"
											>Loss Quantity Cannot More than Quantity Move Out</v-alert>
											<v-alert type="error" dismissible v-model="alertFromServer">{{ServerMsg}}}</v-alert>
											<v-btn color="blue darken-1" text @click="dialog1 = false">Close</v-btn>
											<v-btn color="blue darken-1" text @click="createMoveOut()">Move Out</v-btn>
										</v-card-actions>
									</v-form>
								</v-card>
							</v-dialog>
						</AccessRestricted>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-data-table
					show-expand
					single-expand
					:server-items-length="jobCurrentState.tasks.length"
					:items="jobCurrentState.tasks"
					:expand="expand"
					:headers="headers"
					:hide-default-footer="true"
					style="width: 100%"
					item-key="index"
				>
					<template v-slot:expanded-item="prop">
						<td :colspan="headers.length + 1" style="padding: 0">
							<v-data-table
								:server-items-length="prop.item.progress.length"
								:items="prop.item.progress"
								:headers="header2"
								style="width: 100%"
								:hide-default-footer="true"
							>
								<template v-slot:item.start="{value}">{{value | dateTimeFormat}}</template>
								<template v-slot:item.end="{value}">{{value | dateTimeFormat}}</template>
							</v-data-table>
						</td>
					</template>
				</v-data-table>
			</v-row>
		</v-form>
		<v-row justify="center">
			<v-dialog v-model="masterDialog" persistent max-width="600px">
				<v-card>
					<v-form ref="form">
						<v-card-title>
							<span class="headline">Update Master Quantity</span>
						</v-card-title>
						<v-card-text>
							<v-container grid-list-md>
								<v-row>
									<v-col cols="12" sm="6" md="4">
										<v-text-field label="Master Quantity" required v-model.number="masterQty" type="number" />
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn color="blue darken-1" text @click="masterDialog = false">Close</v-btn>
							<v-btn color="blue darken-1" text @click="updateMaster()">Update Master Quantity</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-dialog>
		</v-row>
	</v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import jobService from '@/services/job-service'
import taskService from '@/services/task-service'
import MoveInQty from '@/views/MoveInQty.vue'
import { Job } from '@/models/job'
import {
	lossBonusMoveOut,
	MoveIn,
	MoveOut,
	Progress,
	Task,
} from '@/models/task'
import { Formatter } from '@/date/date-format'
import moment from 'moment'
import moveOut from '@/views/MoveOut.vue'
import DropdownLabel from '@/components/widgets/form/DropdownLabel.vue'
import lossbonuslist from '@/services/lossbonus-service'
import { LossBonus } from '@/models/lossbonus'
import { CurrentStatus } from '@/models/currentstatus'
import { TableHeader } from '@/components/widgets/models/models'
import dsService from '@/services/datasource-service'
import masterService from '@/services/master-service'
import equipmentService from '@/services/equipment-service'
import { MasterTask } from '@/models/master'
import AccessRestricted from '@/components/AccessRestricted.vue'

interface LossBonusItem {
	quantity: number;
	type: number;
}

@Component({
	components: { MoveInQty, DropdownLabel, moveOut, AccessRestricted },
})
export default class TaskDetail extends Vue {
	job: Partial<Job> = {};

	QueryJob: Job = {} as Job;

	lossBonusItems: LossBonusItem[] = [];
	lossbonus: LossBonus[] = [];
	jobCurrentState: CurrentStatus = {
		job: {},
		currentTask: {},
		tasks: [] as Task[],
	} as CurrentStatus;
	moveInQtyParse: MoveIn = {} as MoveIn;
	moveOutQtyParse: MoveOut = {} as MoveOut;
	lossBonusMoveOutParse: lossBonusMoveOut = {} as lossBonusMoveOut;
	equipmentDatasource = equipmentService.dropdownDatasource();

	creationDate = '';
	dueDate = '';
	completedDate = '';

	masterQty = 0;
	masterQtyParse: MasterTask = {} as MasterTask;

	remarks = '';
	cycleTime = '';
	stateCompleted = false;

	aggregateId = '';
	jobName = '';
	moveInQty = 0;
	dialog = false;
	dialog1 = false;
	masterDialog = false;
	bonusQty = 0;
	lossQty = 0;
	moveOutQty = 0;
	newDialog = false;
	moveOutTotal = 0;
	alert = false;
	alertMoveIn = false;
	alertFromServer = false;
	ServerMsg = '';
	expand = false;

	headers: TableHeader[] = [
		{
			text: 'Process',
			value: 'processName',
		},
		{ text: 'Quantity', value: 'targetQuantity' },
		{ text: 'Loss Bonus', value: 'lossBonus' },
	];

	header2: TableHeader[] = [
		{ text: 'Start Time', value: 'start' },
		{ text: 'End Time', value: 'end' },
		{ text: 'Quantity', value: 'quantity' },
		{ text: 'Cycle Time', value: 'cycleTime' },
		{ text: 'Remarks', value: 'remarks' },
	];

	moveInCreated(fn: () => void) {
		fn()
	}

	newItem() {
		this.lossBonusItems.push({ type: 0, quantity: 0 })
	}

	get totalBonus(): number {
		let total = 0
		this.lossBonusItems.forEach(v => {
			const lb = this.lossbonus.find((lb: LossBonus) => lb.id === v.type)

			if (!lb || lb.type !== 'BONUS') {
				return
			}

			total += v.quantity
		})

		return total
	}

	get totalLoss(): number {
		let total = 0
		this.lossBonusItems.forEach(v => {
			const lb = this.lossbonus.find((lb: LossBonus) => lb.id === v.type)

			if (!lb || lb.type !== 'LOSS') {
				return
			}

			total += v.quantity
		})

		return total
	}

	get jobDueDate(): string {
		const dueDate = this.jobCurrentState.job.dueDate
		if (!dueDate) {
			return ''
		}
		return Formatter.format(dueDate)
	}

	created() {
		this.aggregateId = this.$route.params.id

		lossbonuslist.list().then(v => {
			this.lossbonus = v.data
		})

		jobService.getJob(this.aggregateId).then(v => {
			this.QueryJob = v.data
		})

		jobService.jobCurrentStatus(this.aggregateId).then(v => {
			this.jobCurrentState = v.data
			this.moveInQtyParse.quantity = this.jobCurrentState.currentTask.quantity
			this.moveOutQty = this.moveInQtyParse.quantity
			this.jobName = this.jobCurrentState.job.name
			this.moveInQty = this.jobCurrentState.currentTask.quantity
		})
	}

	clicked(row: any) {
		this.$router.push({ name: 'moveout', params: row.item })
	}

	updateMaster() {
		this.masterQtyParse.quantity = this.masterQty
		masterService
			.updateMasterFromTask(this.aggregateId, this.masterQtyParse)
			.then(r => {
				this.masterDialog = false
				this.$router.push({ name: 'task' })
			})
	}

	createMoveIn() {
		if (this.moveInQtyParse.quantity > this.jobCurrentState.job.quantity) {
			this.alertMoveIn = true
		} else {
			taskService.moveIn(this.aggregateId, this.moveInQtyParse).then(r => {
				this.dialog = false
				this.$router.push({ name: 'task' })
			})
		}
	}

	createMoveOut() {
		this.moveOutTotal = this.totalBonus + this.moveInQty
		this.moveOutQtyParse.remarks = this.remarks
		this.moveOutQtyParse.lossBonus = this.lossBonusItems
		this.moveOutQtyParse.quantity = this.moveInQty
		this.moveOutQtyParse.cycleTime = this.cycleTime
		// this.moveOutQtyParse.quantity = this.moveOutTotal;

		if (this.totalLoss > this.jobCurrentState.currentTask.targetQuantity) {
			this.alert = true
		} else if (this.moveOutQtyParse.quantity <= 0) {
			this.alert = true
		} else if (!this.moveOutQtyParse.quantity) {
			this.alert = true
		} else {
			taskService.moveOut(this.aggregateId, this.moveOutQtyParse).then(r => {
				this.dialog1 = false
				jobService
					.jobCurrentStatus(this.aggregateId)
					.then(v => {
						this.jobCurrentState = v.data
						this.stateCompleted = this.jobCurrentState.completed

						if (this.stateCompleted === true) {
							this.masterDialog = true
						} else {
							this.dialog1 = false
							this.$router.push({ name: 'task' })
						}
					})
					.catch(reason => {
						this.alertFromServer = true
						this.ServerMsg = reason.response.data
					})
			})
		}
	}

	deleteItem(index: number) {
		this.lossBonusItems.splice(index, 1)
	}
}
</script>
