import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

export type HttpResponse<T = any> = AxiosPromise<T>;
export type RequestConfig = AxiosRequestConfig;

// @ts-ignore
const baseUrl = import.meta.env.VITE_APP_API_PREFIX

const instance = axios.create({
	baseURL: baseUrl,
	withCredentials: true,
})

instance.interceptors.request.use(value => value, (err: any) => {
	// request error, handle it
	return Promise.reject(err)
})

instance.interceptors.response.use(v => v, (err: any) => {
	// response error, handle it
	return Promise.reject(err)
})

export const http = instance
