<template>
	<v-container class="page">
		<div class="elevation-1 table-container">
			<v-toolbar flat color="white">
				<v-toolbar-title>{{title}}</v-toolbar-title>
				<v-divider :vertical="true" class="divider-style"/>
				<span class="error-message">{{error}}</span>
				<v-spacer/>
				<v-card-actions>
					<slot name="actions"/>
				</v-card-actions>
			</v-toolbar>
			<v-progress-linear :indeterminate="true"
												 :height="2" v-if="loading"/>
			<v-data-table
				:headers="headers"
				:hide-default-header="headers.length === 0"
				:items="items"
				:search="search">
				<template v-slot:item="props">
					<slot name="item" v-bind="props"/>
				</template>
			</v-data-table>
		</div>
	</v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { TableHeader, TableRetriever } from '../models/models'
import axios from 'axios'

@Component
export default class ListingWidget extends Vue {
	@Prop({ default: '' })
	title: string

	@Prop({ default: () => [] })
	headers: TableHeader[]

	@Prop({ required: true })
	retriever: TableRetriever<any[]>

	@Prop({ default: '' })
	search: string

	loading = false

	items: any[] = []

	error = ''

	cancelSource = axios.CancelToken.source()

	refresh() {
		this.loading = true
		this.error = ''
		this.retriever({ config: { cancelToken: this.cancelSource.token } })
			.then(v => {
				this.items = v.data
			})
			.catch(error => {
				if (error.response) {
					const resp = error.response
					this.error = `${resp.status}: ${resp.data.message}`
				} else if (error.request) {
					const req = error.request as XMLHttpRequest
				} else {
					this.error = error.message
				}
			})
			.finally(() => {
				this.loading = false
			})
	}

	@Watch('retriever', { immediate: true })
	watchRetriever() {
		this.refresh()
	}

	beforeDestroy() {
		this.cancelSource.cancel('Component destroyed')
	}
}
</script>

<style scoped lang="scss">
	.table-container {
		background-color: white;
	}

	.divider-style {
		margin-left: 24px;
		margin-right: 48px;
	}

	.error-message {
		color: darkred;
	}
</style>
