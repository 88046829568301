<template>
	<v-form>
		<p class="page-help">Please enter the details for the job you are creating.</p>
		<p class="section-divider">Job Info</p>
		<v-text-field label="Name *" v-model="form.name"></v-text-field>
		<dropdown label="Product *" v-model="form.productId" :datasource="productDatasource"
							aria-autocomplete=""></dropdown>
		<dropdown label="Job Type *" v-model="form.type" :datasource="jobTypeDatasource"
							aria-autocomplete="list"></dropdown>
		<dropdown
			label="Status"
			v-model="form.status"
			value="NEW"
			:items="['PENDING', 'NEW','COMPLETED']" aria-autocomplete="list"
		></dropdown>
		<v-text-field type="number" label="Quantity *" v-model.number="form.quantity"></v-text-field>
		<date-picker label="Issue Date" v-model="form.dateCreated"></date-picker>
		<date-picker label="Due Date" v-model="form.dueDate"></date-picker>

		<p class="section-divider">Job Material Detail</p>
		<date-picker label="Material Due Date" v-model="form.materialDueDate"></date-picker>
		<date-picker label="Material Receieve Date" v-model="form.materialReceiveDate"></date-picker>

		<p class="section-divider">Customer Info</p>
		<v-text-field label="Quotation" v-model="form.quotation"></v-text-field>
		<v-text-field label="PO" v-model="form.po"></v-text-field>
		<v-text-field label="Invoice" v-model="form.invoice"></v-text-field>
		<v-text-field
			type="number"
			v-model.number="form.customerQuantity"
			label="Customer Request Quantity"
		></v-text-field>
		<date-picker label="Customer Due Date" v-model="form.customerDueDate"></date-picker>
		<dropdown label="Requestor" v-model="form.requestBy" :datasource="employeeDatasource"></dropdown>
		<dropdown label="Customer" v-model="form.customer" :datasource="customerDatasource"></dropdown>

		<v-alert type="error" dismissible v-model="alert">{{this.alertMessage}}</v-alert>
		<v-card-actions>
			<v-spacer></v-spacer>
			<slot></slot>
			<v-btn color="primary" @click="createJob">Create New Job</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
	</v-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import productService from '@/services/product-service'
import dsService from '@/services/datasource-service'
import jobService from '@/services/job-service'
import { Job } from '@/models/job'
import { Product, ProductList } from '../../models/product'

@Component
export default class NewJob extends Vue {
	productDatasource = productService.dropdownDatasource()

	employeeDatasource = dsService.dropdownDatasource('employee')
	jobTypeDatasource = dsService.dropdownDatasource('jobType')
	customerDatasource = dsService.dropdownDatasource('customer')

	form: Partial<Job> = { status: 'NEW' }
	alertMessage = 'Error'
	alert = false

	ProductDetail: Product[] = []

	ProductListing: ProductList[] = []

	created() {
		//	this.ProductListing = productService.dropdownDatasource();

		productService.list('', '').then(v => {
			this.ProductDetail = v.data
		})

		// this.ProductListing[] = this.ProductDetail.values.;
	}

	createJob() {
		this.alert = false
		this.alertMessage = ''

		if (this.form.quantity == null) {
			this.alertMessage = 'Quantity cannot equal to 0'
			this.alert = true
		} else if (this.form.type == null) {
			this.alertMessage = 'Please select Job Type, Job Type cannot be empty'
			this.alert = true
		} else if (this.form.quantity === 0) {
			this.alertMessage = 'Quantity cannot equal to 0'
			this.alert = true
		} else if (this.form.quantity < 0) {
			this.alertMessage = 'Quantity cannot less then 0'
			this.alert = true
		} else if (this.form.productId == null) {
			this.alertMessage = 'Product ID cannot be Empty'
			this.alert = true
		} else if (this.form.name == null) {
			this.alertMessage = 'Job Name cannot be Empty'
			this.alert = true
		} else {
			jobService.create(this.form).then(r => {
				this.$emit('created', this.form)
			}).catch(reason => {
				this.alert = true
				this.alertMessage = reason.response.data
			})
		}
	}
}
</script>

<style scoped lang="scss">
</style>
