<template>
	<v-form @submit.prevent="submit" ref="form">
		<div>
			<p class="page-help">Please enter the details for the Workflow you are creating.</p>

			<p class="section-divider">Workflow Information</p>
			<v-text-field
				v-validate="'required'"
				:error-messages="errors.collect('name')"
				label="Name"
				name="name"
				required
				v-model="form.name"
			/>
			<date-picker label="Date Created" v-model="form.creationDate"/>

			<div class="elevation-1">
				<v-toolbar flat color="white">
					<v-toolbar-title>Processes</v-toolbar-title>
					<v-divider vertical class="mx-4"/>
					<v-spacer/>
					<v-card-actions>
						<v-btn text color="blue darken-4" @click="newItem">New</v-btn>
					</v-card-actions>
				</v-toolbar>
				<v-data-table hide-default-header :server-items-length="workflowItems.length" :items="workflowItems">
					<template v-slot:item="prop">
						<tr>
							<td>
								<dropdown-label
									v-model="prop.item.processId"
									:editMode="prop.item.new"
									@cancel="itemCancelled(prop.index)"
									:items="processes"/>
							</td>
							<td style="white-space: nowrap; width: 1%">
								<v-btn icon @click="orderUp(prop.index)" v-if="prop.index !== 0">
									<v-icon>arrow_upward</v-icon>
								</v-btn>
								<v-btn
									icon
									@click="orderDown(prop.index)"
									v-if="prop.index + 1 !== workflowItems.length">
									<v-icon>arrow_downward</v-icon>
								</v-btn>
								<v-btn icon @click="deleteItem(prop.index)">
									<v-icon>delete</v-icon>
								</v-btn>
							</td>
						</tr>
					</template>
				</v-data-table>
			</div>
			<v-card-actions>
				<v-spacer/>
				<slot/>
				<v-btn type="submit" color="primary">Create New Workflow</v-btn>
			</v-card-actions>
		</div>
	</v-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { DatasourceItem } from '@/models/datasource'
import datasourceService from '@/services/datasource-service'
import { Workflow } from '@/models/Workflow'
import moment from 'moment'
import NewProcess from '@/views/workflow/NewProcess.vue'
import DropdownLabel from '@/components/widgets/form/DropdownLabel.vue'
import workflowService from '@/services/workflow-service'

interface WorkflowItem {
	processId: number;
	new: boolean;
}

@Component({
	components: { NewProcess, DropdownLabel },
})
export default class NewWorkflow extends Vue {
	$refs: {
		form: Vue & { validate(): boolean };
	}

	form: Partial<Workflow> = {
		creationDate: moment(),
	}

	processes: DatasourceItem[] = []
	workflowItems: WorkflowItem[] = []

	newItem() {
		this.workflowItems.push({ processId: 0, new: true })
	}

	orderDown(index: number) {
		const deleted = this.workflowItems.splice(index, 1)
		this.workflowItems.splice(index + 1, 0, deleted[0])
	}

	orderUp(index: number) {
		const deleted = this.workflowItems.splice(index, 1)
		this.workflowItems.splice(index - 1, 0, deleted[0])
	}

	deleteItem(index: number) {
		this.workflowItems.splice(index, 1)
	}

	itemCancelled(index: number) {
		if (this.workflowItems[index].new) {
			this.workflowItems.splice(index, 1)
		}
	}

	created() {
		datasourceService.listItems('process').then(v => {
			this.processes = v.data
		})
	}

	submit() {
		this.$validator.validateAll().then(v => {
			if (v) {
				this.createWorkflow()
			}
		})
	}

	createWorkflow() {
		this.form.items = this.workflowItems.map(v => {
			return { process: v.processId }
		})

		workflowService.create(this.form).then(v => {
			this.$emit('created', v.data)
		})
	}
}
</script>

<style scoped lang="scss">
</style>
