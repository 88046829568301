import { MvouLossBonus } from '@/models/mvoulossbonus'
import { http, HttpResponse, RequestConfig } from '@/http-client'
import { DropdownDatasource } from '@/components/widgets/models/models'

class MvouLossBonusService {
	dropdownDatasource(): DropdownDatasource {
		return ({ config }) => {
			return this.list(config)
		}
	}

	list(config?: RequestConfig): HttpResponse<MvouLossBonus[]> {
		return http.get(`/secured/mvoulossbonus/list`, config)
	}

	createMvouLossBonus(
		mvoulossbonus: Partial<MvouLossBonus>,
		config?: RequestConfig,
	): HttpResponse {
		return http.put(`/secured/mvoulossbonus`, mvoulossbonus, config)
	}
}

export default new MvouLossBonusService()
